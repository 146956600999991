import axios from "axios";


export const login = async (data, setToken, setError) => {
    return await axios.post('https://aurora-team.com/labsWebsite/public/api/user-login', data)
    .then(res => {
      setToken(res.data);
    })
    .catch(err => {
        setError(err.response.data);
    }); 
}

export const register = async (data, setToken, setError) => {
    return await axios.post('https://aurora-team.com/labsWebsite/public/api/user-register', data)
    .then(res => {
        axios.post('https://aurora-team.com/labsWebsite/public/api/user-login', {
            email: res.data.email,
            password: res.data.password,
        })
        .then(res2 => {
          setToken(res2.data);
        })
        .catch(err2 => {
            setError(err2.response.data);
        }); 
    })
    .catch(err => {
        setError(err.response.data);
    }); 
}

export const logout = async (setToken) => {
    setToken(null);
    window.location.reload();
}