import React from "react";

function OverviewContainer({ section }) {
  return (
    <div
      className={`${
        section === "Overview" ? "block" : "hidden"
      } w-full h-full pt-5`}
    >
      <div className="flex flex-col space-y-5">
        <div className="space-y-1">
          <h1 className="font-Lato-Bold text-lg">USEFUL FOR</h1>
          <p className="font-Lato-Regular">
            Rapid, qualitative susceptibility testing of Mycobacterium
            tuberculosis complex isolates growing in pure culture
          </p>
        </div>
        <div className="space-y-5">
          <p className="font-Lato-Regular">
            Affirming the initial choice of chemotherapy for M tuberculosis
            infections
          </p>
          <p>Confirming the emergence of drug resistance</p>
          <p className="font-Lato-Regular">
            Guiding the choice of alternate agents for therapy for M
            tuberculosis infections
          </p>
        </div>
        <div className="w-full">
          <h1 className="font-Lato-Bold text-lg">REFLEX ANALYSES</h1>
          <table className="w-full mt-5">
            <tr className="border-b-[1px] border-[#707070] ">
              <td className="px-2  pb-2 font-Lato-Regular">Test ID</td>
              <td className="px-2 pb-2 font-Lato-Regular">Reporting Name</td>
              <td className="px-2 pb-2 font-Lato-Regular">
                Available Separately
              </td>
              <td className="px-2 pb-2 font-Lato-Regular">Always Performed</td>
            </tr>
            <tr className=" bg-[#F5F5F5]">
              <td className="px-2 py-2 font-Lato-Regular">STV2</td>
              <td className="px-2 py-2 font-Lato-Regular">
                Susceptibility, Mtb Cx, 2nd Line
              </td>
              <td className="px-2 py-2 font-Lato-Regular">No, (Bill Only)</td>
              <td className="px-2 py-2 font-Lato-Regular">No</td>
            </tr>
          </table>

          <table className="w-full mt-10">
            <tr className="border-b-[1px] border-[#707070] ">
              <td className="px-2  pb-2 font-Lato-Regular">Test ID</td>
              <td className="px-2 pb-2 font-Lato-Regular">Reporting Name</td>
              <td className="px-2 pb-2 font-Lato-Regular">
                Available Separately
              </td>
              <td className="px-2 pb-2 font-Lato-Regular">Always Performed</td>
            </tr>
            <tr className=" bg-[#F5F5F5]">
              <td className="px-2 py-2 font-Lato-Regular">STV2</td>
              <td className="px-2 py-2 font-Lato-Regular">
                Susceptibility, Mtb Cx, 2nd Line
              </td>
              <td className="px-2 py-2 font-Lato-Regular">No, (Bill Only)</td>
              <td className="px-2 py-2 font-Lato-Regular">No</td>
            </tr>
          </table>
        </div>

        <div className="w-full px-2">
          <h1 className="font-Lato-Bold text-lg">TestING ALGORITHM</h1>
          <p className="font-Lato-Regular text-lg mt-2">
            When this test is ordered, the additional test will always be
            performed and charged separately.
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            If resistance to a first line antimicrobial agent is detected reflex
            testing for confirmation of resistance and second line agents will
            be performed and charged.
          </p>
        </div>
        <div className="space-y-2 px-2">
          <h1 className="font-Lato-Bold text-lg">SPECIAL INSTRUCTIONS</h1>
          <p className="font-Lato-Regular text-lg mt-2">
            Infectious Specimen Shipping Guidelines
          </p>
          <h1 className="font-Lato-Bold text-lg">NY STATE AVAILABLE </h1>
          <p className="font-Lato-Regular text-lg mt-2">Yes</p>
          <h1 className="font-Lato-Bold text-lg">ALIASES</h1>
          <p className="font-Lato-Regular text-lg mt-2">
            Acid-Fast Bacilli (AFB)
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            AFB (Acid-Fast Bacilli)
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Antibiotic Susceptibility
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Antimicrobial Susceptibility, Mycobacterium tuberculosis
          </p>
          <p className="font-Lato-Regular text-lg mt-2">Bacillus, Acid-Fast</p>
          <p className="font-Lato-Regular text-lg mt-2">
            MTB (Mycobacterium tuberculosis)
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Mycobacteria Antimicrobial Susceptibility (MIC) (Minimum Inhibitory
            Concentration)
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Mycobacterium tuberculosis (MTB)
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Susceptibility Testing
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Susceptibility, Mycobacterium tuberculosis
          </p>
          <p className="font-Lato-Regular text-lg mt-2">TB (Tuberculosis)</p>
          <p className="font-Lato-Regular text-lg mt-2">
            Tubercle Bacilli: Mycobacterium tuberculosis
          </p>
          <p className="font-Lato-Regular text-lg mt-2">Tuberculosis (TB)</p>
        </div>
      </div>
    </div>
  );
}

export default OverviewContainer;
