import React from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import logo from "../../Images/Logo.svg";
import WriteIcon from "../../Images/WriteIcon.svg";
import PhoneIcon1 from "../../Images/PhoneIcon1.svg";
import EmailIcon1 from "../../Images/EmailIcon1.svg";

function AccountSettings() {
  return (
    <div className="w-full h-full">
      <Header Section="Profile" />
      <div className="w-full h-full px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex  space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">Profile</p>
          <p className="font-Lato-Regular text-xl">Account Settings</p>
        </div>
      </div>

      <div className="flex flex-col  lg:pr-20 mt-10">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex flex-col justify-center  items-center lg:items-start lg:w-full lg:pl-20">
            <p className="font-Lato-Bold text-3xl">Account Settings</p>
          </div>
        </div>
        <div className="lg:pl-20 mt-10">
          <div className="w-full flex flex-col lg:flex-row justify-between bg-[#F5F5F5] rounded-xl pb-10">
            <div className="mt-10 w-full mx-auto px-5 lg:px-0 lg:ml-10  flex flex-col">
              <div className="flex space-x-20">
                <img src={logo} className="w-[164px] h-[164px] " />
                <div className="flex flex-col md:flex-row justify-center h-fit md:space-x-5 mr-5">
                  <div className="bg-gradient-to-r from-[#4A5428] to-[#B7C933] h-14 flex items-center px-14 py-2 rounded-xl mt-14 border-2">
                    <p className="text-xl  font-Lato-Bold text-white">Edit</p>
                  </div>
                  <div className="bg-[#FFFFFF] border-[#93A750] w-fit flex items-center py-2 px-10 rounded-xl mt-14 border-2">
                    <p className="text-xl  font-Lato-Bold text-[#93A750]">
                      Delete
                    </p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pr-5 justify-between w-full">
                <div className="flex flex-col rounded-xl mt-10    space-y-5 pb-10">
                  <p className="font-Roboto-Regular text-xl ml-1">User name</p>
                  <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-md ">
                    <input
                      name="UserName"
                      placeholder="exampl"
                      type="text"
                      className="w-full bg-whitefont-Poppins-Regular text-xs text-[#707070] outline-0 ring-0"
                    />
                    <img src={WriteIcon} className="w-[18px] h-[18px]" />
                  </div>
                  <p className="font-Roboto-Regular text-xl ml-1">Your Email</p>
                  <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-md ">
                    <img src={EmailIcon1} className="w-[18px] h-[18px]" />
                    <input
                      name="email"
                      placeholder="example@gmail.com"
                      type="text"
                      className="w-full bg-whitefont-Poppins-Regular text-xs text-[#707070] outline-0 ring-0"
                    />
                    <img src={WriteIcon} className="w-[18px] h-[18px]" />
                  </div>
                  <p className="font-Roboto-Regular text-xl ml-1">
                    Contact Number
                  </p>
                  <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-md ">
                    <img src={PhoneIcon1} className="w-[18px] h-[18px]" />
                    <input
                      name="Phone"
                      placeholder="+970 592 736075"
                      type="text"
                      className="w-full bg-whitefont-Poppins-Regular text-xs text-[#707070] outline-0 ring-0"
                    />
                    <img src={WriteIcon} className="w-[18px] h-[18px]" />
                  </div>

                  <p className="font-Roboto-Regular text-xl ml-1">
                    The address
                  </p>
                  <div className="grid grid-cols-2 gap-5">
                    <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-md ">
                      <input
                        name="City"
                        placeholder="City"
                        type="text"
                        className="w-full bg-whitefont-Poppins-Regular text-xs text-[#707070] outline-0 ring-0"
                      />
                    </div>
                    <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-md ">
                      <input
                        name="Country"
                        placeholder="Country"
                        type="text"
                        className="w-full bg-whitefont-Poppins-Regular text-xs text-[#707070] outline-0 ring-0"
                      />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col rounded-xl md:mt-10   pb-10">
                  <p className="font-Roboto-Regular text-xl ">Password</p>

                  <p className="font-Roboto-Regular text-xl ml-1 mt-8">
                    Old Password
                  </p>

                  <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-md ">
                    <input
                      name="UserName"
                      placeholder="exampl"
                      type="text"
                      className="w-full bg-whitefont-Poppins-Regular text-xs text-[#707070] outline-0 ring-0"
                    />
                    <img src={WriteIcon} className="w-[18px] h-[18px]" />
                  </div>
                  <p className="font-Roboto-Regular text-xl ml-1">
                    New Password
                  </p>
                  <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4 px-4    relative m-auto border-[1px] rounded-md ">
                    <img src={EmailIcon1} className="w-[18px] h-[18px]" />
                    <input
                      name="email"
                      placeholder="example@gmail.com"
                      type="text"
                      className="w-full bg-whitefont-Poppins-Regular text-xs text-[#707070] outline-0 ring-0"
                    />
                    <img src={WriteIcon} className="w-[18px] h-[18px]" />
                  </div>

                  <div className="w-full flex justify-end mt-2 md:mt-0">
                    <div className="bg-gradient-to-r from-[#4A5428] to-[#B7C933] h-14 flex items-center w-fit px-8 py-1 rounded-xl  border-2">
                      <p className="text-xl  font-Lato-Bold text-white">Edit</p>
                    </div>
                  </div>

                  <div className="flex justify-end space-x-5">
                    <div className="bg-gradient-to-r from-[#4A5428] to-[#B7C933] h-14 flex items-center px-14 py-2 rounded-xl mt-14 border-2">
                      <p className="text-xl  font-Lato-Bold text-white">Save</p>
                    </div>
                    <div className="bg-[#FFFFFF] border-[#93A750] w-fit flex items-center  px-10 rounded-xl mt-14 border-2">
                      <p className="text-xl  font-Lato-Bold text-[#93A750]">
                        Cancel
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default AccountSettings;
