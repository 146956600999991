import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ListAddTest from "../../components/Ordering/ListAddTest";

import {getAllTests} from '../../Api/tests';
function AddTestSection() {
  const [tests, setTests] = useState([])
  useEffect(() => {
    getAllTests(setTests);
  }, []);
  return (
    <div className="w-full h-full">
      <Header Section="Ordering" />
      <div className="w-full h-full flex flex-col px-1 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <Link
            to="/Ordering"
            className="font-Lato-Regular text-xl text-[#B7C933]"
          >
            Ordering & Results
          </Link>
          <p className="font-Lato-Regular text-xl pl-5">
            Add Tests to an Order
          </p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">Add Tests to an Order</h1>
          <p className="font-Lato-Regular text-lg mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <ListAddTest Data={tests} />
      </div>
      <Footer />
    </div>
  );
}

export default AddTestSection;
