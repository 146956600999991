import axios from "axios";


export const getAllTests = async (set, setError) => {
    return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-all-tests')
    .then(res => {
      set(res.data);
    })
    .catch(err => {
        // setError(err.response.data);
    }); 
}

// tests less than 90 days
export const getNewTests = async (set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-test-by-date')
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}

// new test request
export const sendTestRequest = async (data, setError) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/add-to-order', data)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
      setError(err.response.data.errors);
  }); 
}


// test types
export const getTestTypes = async (set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-types')
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}

export const getTestByType = async (typeID, set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-tests-by-type/' + typeID)
  .then(res => {
    set(res.data);
  })
  .catch(err => {
    // setError(err.response.data);
  }); 
}


// add test
export const addTest = async (data, setError) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/add-test', data)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
    console.log(err);
      // setError(err.response.data.errors);
  }); 
}

export const editTest = async (id, data, setError) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/update-test/' + id, data)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
    console.log(err);
      // setError(err.response.data.errors);
  }); 
}
export const deleteTest = async (id, setError) => {
  return await axios.delete('https://aurora-team.com/labsWebsite/public/api/delete-test/' + id)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
    console.log(err);
      // setError(err.response.data.errors);
  }); 
}

export const getTestById = async (id) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-test/' + id)
}
