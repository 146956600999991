import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import AddTestsIcon from "../Images/AddTestsIcon.svg";
import TestPriceIcon from "../Images/TestPriceIcon.svg";
import MyResultIcon from "../Images/MyResultIcon.svg";

function Ordering() {
  return (
    <div className="w-full h-full">
      <Header Section="Ordering" />
      <div className="w-full h-full px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">Ordering & Results</p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">Ordering & Results</h1>
        </div>
        <div className="grid grid-cols-1  gap-y-5  lg:grid-cols-2 mt-10 pl-5 gap-x-5">
          <Link
            to="/Ordering/AddTest"
            className="flex space-x-4 border-[1px] p-5 rounded-xl"
          >
            <img src={AddTestsIcon} />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">
                Add Tests to an Order
              </p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                neque quam
              </p>
            </div>
          </Link>

          <Link
            to="/Ordering/TestPrices"
            className="flex space-x-4 border-[1px] p-5 rounded-xl"
          >
            <img src={TestPriceIcon} className="w-20" />
            <div className="flex flex-col ">
              <p className="text-xl font-Lato-Bold mb-1">Test Prices</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                neque quam
              </p>
            </div>
          </Link>
          <div className="flex space-x-4 border-[1px] lg:col-start-1 lg:col-end-3 w-fit mx-auto p-5 rounded-xl">
            <img src={MyResultIcon} className="w-20" />
            <div className="flex flex-col ">
              <p className="text-xl font-Lato-Bold mb-1">My Test Results</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                neque quam
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Ordering;
