import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ListTestPrice from "../../components/Ordering/ListTestPrice";
import {getAllTests} from '../../Api/tests';

function TestPriceSection() {
  const [tests, setTests] = useState([])

  useEffect(() => {
    getAllTests(setTests);
  }, []);
//   const tests = [
//     {
//       TestID: "VZIKM",
//       TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
//       Published: "11/22/2022",
//     },
//     {
//       TestID: "VZIKM",
//       TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
//       Published: "11/22/2022",
//     },
//     {
//       TestID: "VZIKM",
//       TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
//       Published: "11/22/2022",
//     },
//     {
//       TestID: "VZIKM",
//       TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
//       Published: "11/22/2022",
//     },
//     {
//       TestID: "VZIKM",
//       TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
//       Published: "11/22/2022",
//     },
//     {
//       TestID: "VZIKM",
//       TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
//       Published: "11/22/2022",
//     },
//     {
//       TestID: "VZIKM",
//       TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
//       Published: "11/22/2022",
//     },
//   ];
  return (
    <div className="w-full h-full">
      <Header Section="Ordering" />
      <div className="w-full h-full flex flex-col px-1 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <Link
            to="/Ordering"
            className="font-Lato-Regular text-xl text-[#B7C933]"
          >
            Ordering & Results
          </Link>
          <p className="font-Lato-Regular text-xl pl-5">Test Prices</p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">Test Prices</h1>
          <p className="font-Lato-Regular text-lg mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <ListTestPrice Data={tests} />
      </div>
      <Footer />
    </div>
  );
}

export default TestPriceSection;
