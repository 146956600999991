import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import ListAllTest from "../../components/TestCatalogComponents/AllTest/ListAllTest";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import {getAllTests} from '../../Api/tests';

function AllTest() {
  const [tests, setTests] = useState([])

  useEffect(() => {
    getAllTests(setTests);
  }, []);
  return (
    <div className="w-full h-full">
      <Header Section="TestCatalog" />
      <div className="w-full h-full flex flex-col px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <Link
            to="/TestCatalog"
            className="font-Lato-Regular text-xl text-[#B7C933]"
          >
            Test Catalog
          </Link>
          <p className="font-Lato-Regular text-xl pl-5">All Test</p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">All Test</h1>
          <p className="font-Lato-Regular text-lg mt-4">
            New analyses published in the previous 90 days are listed in the
            following table.
          </p>
        </div>

        <ListAllTest Data={tests} />
      </div>
      <Footer />
    </div>
  );
}

export default AllTest;
