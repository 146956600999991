import React, { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoTrashOutline } from "react-icons/io5";

import { Link } from "react-router-dom";
import { VscListFlat } from "react-icons/vsc";
import { TiEdit } from "react-icons/ti";
import HeaderDB from "../../components/DashBoard/HeaderDB";
import SideBarDB from "../../components/DashBoard/SideBarDB";
import DeleteStoreDB from "../../components/DashBoard/Store/DeleteStoreDB";
import EditStoreDB from "../../components/DashBoard/Store/EditStoreDB";
import AddStoreDB from "../../components/DashBoard/Store/AddStoreDB";
import StoreDBPangration from "../../components/DashBoard/Store/StoreDBPangration";

import {storeGet} from '../../Api/store'

function StoreDB() {
  const [OpenEditStore, setOpenEditStore] = useState(false);
  const [OpenAddStore, setOpenAddStore] = useState(false);
  const [OpenDeleteStore, setOpenDeleteStore] = useState(false);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      

  function drawer() {
    document.getElementById("drawerBody").classList.remove("hidden");
    document
      .getElementById("drawerBody")
      .classList.add(
        "flex",
        "fixed",
        "h-screen",
        "w-full",
        "bg-transparent",
        "top-0",
        "left-0",
        "lg:hidden",
        "z-10"
      );
  }
  function closeDrawer() {
    document.getElementById("drawerBody").classList.add("hidden");
  }

  const [labs, setLabs] = useState(false);
  const [selectedId, setSelectedId] = useState(false);

  useEffect(() => {
    storeGet(setLabs);
  }, []);

  return (
    <div className="w-full h-full pr-5 p-5 bg-[#F2F4F7]">
      <AddStoreDB open={OpenAddStore} setOpen={setOpenAddStore} />
      <EditStoreDB open={OpenEditStore} setOpen={setOpenEditStore} selectedId={selectedId} />
      <DeleteStoreDB open={OpenDeleteStore} setOpen={setOpenDeleteStore} selectedId={selectedId}/>

      <div className="w-full flex ">
        <div className="bg-white mr-[-1rem] lg:mr-0 rounded-l-xl ">
          <VscListFlat
            id="drawerbtn"
            className="text-black  text-xl m-2  cursor-pointer w-10 h-20 lg:hidden "
            onClick={() => drawer()}
          />
        </div>
        <HeaderDB />
      </div>
      <div className="flex ">
        <SideBarDB page="Store" />
        <div className="w-full h-full lg:ml-8 mt-10 pb-48">
          {/* Pangration */}
          <div className={`  mt-10 mb-10`}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full  ">
              <div className="w-full  pr-2 py-1 bg-[#F9FAFF] rounded-xl flex border-[1px] border-[#E4E7EC]  items-center mr-5">
                <select
                  name="Product"
                  className=" w-full   rounded-lg bg-[#F9FAFF]   font-Poppins-Regular  text-[#98A2B3] text-xs  outline-none px-4 py-2 cursor-pointer"
                >
                  <option value="" selected disabled hidden className="">
                    Product
                  </option>
                </select>
              </div>
              <div className="flex justify-between space-x-5">
                <div className="border-[#E4E7EC] w-[50%] h-fit bg-[#F9FAFF] flex space-x-2 items-center py-3 px-4  relative border-[1px] rounded-xl ">
                  <input
                    id="Quantity"
                    placeholder="Quantity"
                    type="text"
                    className="w-full bg-[#F9FAFF] font-Poppins-Medium text-xs placeholder:text-[#98A2B3] outline-0 ring-0"
                  />
                </div>
                <div className=" bg-[#0D2135] w-[30%] lg:w-[25%]  flex items-center justify-center px-5 lg:px-5  py-2 rounded-xl cursor-pointer "
                    onClick={() => setOpenAddStore(true)}
                  >
                  <p className="text-sm flex items-center text-center justify-center text-white font-Poppins-Medium">
                    Add to table
                  </p>
                </div>
              </div>
              <p
                id="message"
                className="text-sm text-red-500 flex items-center"
              ></p>
            </div>

          {/* Pangration */}
          {labs && (
            <StoreDBPangration
              itemsPerPage={8}
              Data={labs}
              setOpenEditStore={setOpenEditStore}
              setOpenAddStore={setOpenAddStore}
              setOpenDeleteStore={setOpenDeleteStore}
              setSelectedId={setSelectedId}
            />
          )}
          </div>
          {/* Drawer */}
          <div id="drawerBody" className=" hidden  ">
            <div
              id="drawer"
              className=" w-full bg-[#0D2135] opacity-80 h-full md:w-1/2"
            >
              <div className="p-4">
                <AiOutlineClose
                  className="text-xl text-white cursor-pointer "
                  onClick={() => closeDrawer()}
                />
              </div>
              <div className="flex flex-col ml-10 md:ml-20 space-y-1 w-full justify-center  h-4/5 text-white ">
                {/* border-b-2 border-[#847244] */}
                <Link
                  to="/SystemDashBoard"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center  text-xl  p-2 md:w-1/2 w-3/4"
                >
                  <div className="font-Poppins-Regular text-sm">Colors</div>
                </Link>
                <Link
                  to="/SystemDashBoard/Analytic"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2 "
                >
                  <div className="font-Poppins-Regular text-sm">Analytic </div>
                </Link>
                <Link
                  to="/SystemDashBoard/Labs"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Labs </div>
                </Link>
                <Link
                  to="/SystemDashBoard/Quantity"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Quantity </div>
                </Link>
                <Link
                  to="/SystemDashBoard"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Logout</div>
                </Link>
              </div>
            </div>
            <div className="hidden sm:block h-full w-full bg-black opacity-40" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default StoreDB;
