import React from "react";

function Specimen({ section }) {
  return (
    <div
      className={`${
        section === "SPECIMEN" ? "block" : "hidden"
      } w-full h-full pt-5`}
    >
      <div className="flex flex-col space-y-5">
        <div className="space-y-2">
          <h1 className="font-Lato-Bold text-lg">ANALYSESING ALGORITHM</h1>
          <p className="font-Lato-Regular text-lg mt-2">
            The following algorithms are available:
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            -Assessment for Zika Virus Infection
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            -Mosquito-borne Disease Laboratory Testing
          </p>
        </div>
        <div>
          <h1 className="font-Lato-Bold text-lg">SPECIMEN TYPE </h1>
          <p className="font-Lato-Regular text-lg mt-2">Serum</p>
        </div>
        <div>
          <h1 className="font-Lato-Bold text-lg">ORDERING GUIDANCE </h1>
          <p className="font-Lato-Regular text-lg mt-2">
            For specimens collected less than 14 days post-symptom onset or
            possible Zika virus exposure, reverse transcription-polymerase chain
            reaction for Zika virus on serum and urine to exclude a
            false-negative Zika virus IgM result is recommended. For more
            information see RZIKU / Zika Virus, PCR, Molecular Detection,
            Random, Urine and RZIKS / Zika Virus, PCR, Molecular Detection,
            Serum
          </p>
        </div>
        <div>
          <h1 className="font-Lato-Bold text-lg">
            ADDITIONAL TESTING REQUIREMENTS
          </h1>
          <p className="font-Lato-Regular text-lg mt-2">
            This is a screening test for Zika virus. As required by your local
            health department, confirmatory testing of a presumptive positive
            result may be necessary.
          </p>
          <p className="font-Lato-Regular text-lg mt-10">
            Due to similar clinical presentation and cross reactivity, testing
            for IgM-class antibodies to dengue virus is recommended to occur
            concurrently with Zika virus IgM testing. Order DENVP / Dengue Virus
            Antibody/Antigen Panel, Serum.
          </p>
        </div>
        <div>
          <h1 className="font-Lato-Bold text-lg">SPECIMEN REQUIRED</h1>
          <h1 className="font-Lato-Bold text-lg mt-1">
            Collection Container/Tube:
          </h1>
          <h1 className="font-Lato-Bold text-lg mt-5">
            Preferred:{" "}
            <span className="font-Lato-Regular text-base">Serum gel</span>
          </h1>
          <h1 className="font-Lato-Bold text-lg mt-5">
            Acceptable:{" "}
            <span className="font-Lato-Regular text-base">Red top</span>
          </h1>
          <h1 className="font-Lato-Bold text-lg mt-5">
            Submission Container/Tube:{" "}
            <span className="font-Lato-Regular text-base">Plastic vial</span>
          </h1>
          <h1 className="font-Lato-Bold text-lg mt-5">
            Specimen Volume:{" "}
            <span className="font-Lato-Regular text-base">2.5 mL</span>
          </h1>
          <h1 className="font-Lato-Bold text-lg mt-5">
            Collection Instructions:{" "}
          </h1>
          <p className="font-Lato-Regular text-lg mt-2">
            1. Allow blood to clot at room temperature (20-25 degrees C) for 30
            to 60 minutes, then centrifuge and aliquot serum into plastic vial.
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            2. Send serum specimen frozen.
          </p>
          <h1 className="font-Lato-Bold text-lg mt-5">
            SPECIMEN MINIMUM VOLUME :{" "}
          </h1>
          <p className="font-Lato-Regular text-lg mt-2">2 mL</p>
        </div>
        <div className="">
          <h1 className="font-Lato-Bold text-lg mb-5">REJECT DUE TO</h1>
          <div className="bg-[#F5F5F5] flex justify-between pl-5 pr-20 py-2">
            <p>Gross hemolysis</p>
            <p>Reject</p>
          </div>
          <div className="bg-white flex justify-between pl-5 pr-20 py-2">
            <p>Gross lipemia</p>
            <p>Reject</p>
          </div>
          <div className="bg-[#F5F5F5] flex justify-between pl-5 pr-20 py-2">
            <p>Gross icterus</p>
            <p>Reject</p>
          </div>
          <div className="bg-white flex justify-between pl-5 pr-20 py-2">
            <p>Heat-inactivated specimen</p>
            <p>Reject</p>
          </div>
        </div>
        <div>
          <h1 className="font-Lato-Bold text-lg mt-5">
            SPECIMEN STABILITY INFORMATION
          </h1>

          <table className="w-full mt-5">
            <tr className="border-b-[1px] border-[#707070] ">
              <td className="px-2  pb-2 font-Lato-Regular">Specimen Type</td>
              <td className="px-2 pb-2 font-Lato-Regular">Temperature</td>
              <td className="px-2 pb-2 font-Lato-Regular">Time</td>
              <td className="px-2 pb-2 font-Lato-Regular">Special Container</td>
            </tr>
            <tr className=" bg-[#F5F5F5]">
              <td className="px-2 py-2 font-Lato-Regular">Serum</td>
              <td className="px-2 py-2 font-Lato-Regular">Frozen</td>
              <td className="px-2 py-2 font-Lato-Regular">30 days</td>
              <td className="px-2 py-2 font-Lato-Regular"></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Specimen;
