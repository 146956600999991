import React from "react";

function Setup({ section }) {
  return (
    <div
      className={`${
        section === "SETUP & UPDATES" ? "block" : "hidden"
      } w-full h-full pt-5`}
    >
      <div className="flex flex-col space-y-5">
        <div className="space-y-4">
          <h1 className="font-Lato-Regular text-xl">Test Setup Resources</h1>
          <h1 className="font-Lato-Regular text-xl">SETUP FILES </h1>

          <p className="font-Lato-Regular text-[#81B8E8]">Excel | Pdf</p>
          <h1 className="font-Lato-Regular text-xl">SAMPLE REPORTS </h1>

          <p className="font-Lato-Regular text-[#81B8E8]">
            Normal Reports | Abnormal Reports
          </p>
        </div>
        <div className="w-full ">
          <h1 className="font-Lato-Bold text-xl mb-3">
            Test Update Resources{" "}
          </h1>

          <table className="w-full mt-5">
            <tr className="border-b-[1px] border-[#707070] ">
              <td className="px-2  pb-2 font-Lato-Regular">Change Type</td>
              <td className="px-2 pb-2 font-Lato-Regular">Effective Date</td>
            </tr>
            <tr className=" bg-[#F5F5F5]">
              <td className="px-2 py-2 font-Lato-Regular">New analyses</td>
              <td className="px-2 py-2 font-Lato-Regular">2022-11-22</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Setup;
