import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { RiSendPlaneLine } from "react-icons/ri";
import { TiEdit } from "react-icons/ti";
import { IoTrashOutline } from "react-icons/io5";
import { BsThreeDots } from "react-icons/bs";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { HiOutlineArrowRight } from "react-icons/hi";
import { AiOutlineEye } from "react-icons/ai";

// Example items, to simulate fetching from another resources.

function Items({ currentItems, setOpenShowAccountDetails }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
          <tr className="border-b-[1px] ">
            <td className="w-fit">
              <input
                name="checkVIP"
                type="checkbox"
                className="text-[#E4E7EC] border-[#E4E7EC] border-[1px] ml-5 w-fit"
              />
            </td>
            <td className="font-Poppins-Regular text-black text-base font-semibold py-6 flex flex-col">
              <p>{item.labName}</p>
            </td>
            <td className="font-Poppins-Regular text-black text-base font-semibold py-6 ">
              {item.email}
            </td>
            <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-2">
              {item.phone}
            </td>
            <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-2">
              {item.managerPhone}
            </td>
            <td className="font-Poppins-Regular text-black text-base pl-5 font-semibold py-6">
              {item.address}
            </td>
            <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-5">
              {item.country}
            </td>
            <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-5">
              {item.city}
            </td>
            <td className="font-Poppins-Regular text-black text-base font-semibold py-6 pl-5">
              {item.type}
            </td>
            <td>
              <div className="flex space-x-2 py-4">
                <AiOutlineEye
                  className="text-2xl text-black cursor-pointer"
                  onClick={() => setOpenShowAccountDetails(true)}
                />
              </div>
            </td>
          </tr>
        ))}
    </>
  );
}

function VIPPangration({
  setOpenShowAccountDetails,
  section,
  itemsPerPage,
  Data,
}) {
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = Data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(Data.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % Data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );

    setItemOffset(newOffset);
  };
  const Checkall = () => {
    if (document.getElementById("HeadCheckVIP").checked) {
      let checks = document.getElementsByName("checkVIP");
      for (let i = 0; i <= checks.length; i++) {
        checks[i].checked = true;
      }
    } else if (!document.getElementById("HeadCheckVIP").checked) {
      let checks = document.getElementsByName("checkVIP");
      for (let i = 0; i <= checks.length; i++) {
        checks[i].checked = false;
      }
    }
  };
  return (
    <div
      className={`${section === "VIP" ? "block" : "hidden"} overflow-x-scroll`}
    >
      <table className="w-full h-full mt-5 bg-white rounded-t-2xl ">
        <tr className="border-b-[1px] w-full">
          <td className="w-fit  pr-2 lg:pr-0">
            <input
              id="HeadCheckVIP"
              type="checkbox"
              className="text-[#E4E7EC] border-[#E4E7EC] border-[1px] ml-5 "
              onClick={() => Checkall()}
            />
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2  pr-20 lg:pr-0">
            Lab name
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2   pr-20 lg:pr-0">
            email
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2 pl-2 pr-20 lg:pr-0">
            Phone
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2 w-[8%] pr-20 lg:pr-0 ">
            manager Phone
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2 pl-5 pr-20 lg:pr-0">
            address
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2 pl-5 pr-20 lg:pr-0">
            city
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2 pl-5 pr-20 lg:pr-0">
            country
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2 pl-5 pr-20 lg:pr-0">
            type
          </td>
          <td className="text-sm text-[#98A2B3] font-Poppins-Regular py-2 w-[8%] pr-20 lg:pr-0">
            Action
          </td>
        </tr>
        <Items
          setOpenShowAccountDetails={setOpenShowAccountDetails}
          currentItems={currentItems}
          className="w-full bg-white"
        />
      </table>

      <ReactPaginate
        breakLabel={<BsThreeDots />}
        nextLabel={
          <div className="bg-white py-[0.4rem] px-2  rounded-lg">
            <HiOutlineArrowRight className="bg-white text-xl" />
          </div>
        }
        onPageChange={handlePageClick}
        pageRangeDisplayed={1}
        pageCount={pageCount}
        previousLabel={
          <div className="bg-white py-[0.4rem] px-2  rounded-lg">
            <HiOutlineArrowLeft className="bg-white text-xl" />
          </div>
        }
        pageClassName="bg-white  font-Poppins-Regular py-1 px-3 rounded-lg"
        containerClassName="flex justify-center space-x-5 mb-2 border-gray-400 w-full  bottom-0   items-center mt-10"
        activeClassName="bg-[#B7C835] text-[#FFFFFF] "
        renderOnZeroPageCount={null}
      />
    </div>
  );
}
export default VIPPangration;
