import React, { useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";

import { Link } from "react-router-dom";
import { VscListFlat } from "react-icons/vsc";
import SideBarDB from "../components/DashBoard/SideBarDB";
import HeaderDB from "../components/DashBoard/HeaderDB";
import NormalPangration from "../components/DashBoard/Accounts/NormalPangration";
import VIPPangration from "../components/DashBoard/Accounts/VIPPangration";
import AllPangration from "../components/DashBoard/Accounts/AllPangration";
import ShowAccountDetails from "../components/DashBoard/Accounts/ShowAccountDetails";

const Accounts = [
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
  {
    id: 1,
    labName: "halabi",
    email: "example@gmail",
    phone: "4564623",
    managerPhone: "2131242141",
    address: "UK , 1 Avenue",
    password: "12323sadas",
    country: "London",
    city: "Uk",
    type: "Vip/Normal",
  },
];

function DashBoard() {
  const [OpenShowAccountDetails, setOpenShowAccountDetails] = useState(false);
  const [Section, setSection] = useState("Normal");

  function drawer() {
    document.getElementById("drawerBody").classList.remove("hidden");
    document
      .getElementById("drawerBody")
      .classList.add(
        "flex",
        "fixed",
        "h-screen",
        "w-full",
        "bg-transparent",
        "top-0",
        "left-0",
        "lg:hidden",
        "z-10"
      );
  }
  function closeDrawer() {
    document.getElementById("drawerBody").classList.add("hidden");
  }
  return (
    <div className="w-full h-full pr-5 p-5 bg-[#F2F4F7]">
      <ShowAccountDetails
        open={OpenShowAccountDetails}
        setOpen={setOpenShowAccountDetails}
      />

      <div className="w-full flex ">
        <div className="bg-white mr-[-1rem] lg:mr-0 rounded-l-xl ">
          <VscListFlat
            id="drawerbtn"
            className="text-black  text-xl m-2  cursor-pointer w-10 h-20 lg:hidden "
            onClick={() => drawer()}
          />
        </div>
        <HeaderDB />
      </div>
      <div className="flex ">
        <SideBarDB page="Accounts" />
        <div className="w-full h-full lg:ml-8 mt-10">
          <div className="flex w-full justify-between">
            <div className="flex w-full space-x-2">
              <div className="w-fit pr-2 bg-white rounded-lg flex items-center mr-5">
                <select className=" w-fit  rounded-lg font-Poppins-Medium  text-base outline-none px-4 py-2 cursor-pointer">
                  <option value="" selected disabled hidden>
                    Sort by
                  </option>
                  <option value="A-Z">A-Z</option>
                  <option value="Z-A">Z-A</option>
                </select>
              </div>
              <div className="w-fit flex  space-x-2">
                <div
                  className={`${
                    Section === "All" ? "bg-[#B7C835]" : "bg-white"
                  } w-fit flex items-center px-4 py-2 lg:py-0 rounded-xl cursor-pointer`}
                  onClick={() => setSection("All")}
                >
                  <p
                    id="All"
                    className={`${
                      Section === "All" ? "text-white" : "text-[#101828]"
                    }  text-xs text-center `}
                  >
                    All
                  </p>
                </div>
                <div
                  className={`${
                    Section === "Normal" ? "bg-[#B7C835]" : "bg-white"
                  } w-fit flex items-center px-4 py-2 lg:py-0 rounded-xl cursor-pointer`}
                  onClick={() => setSection("Normal")}
                >
                  <p
                    id="Normal"
                    className={`${
                      Section === "Normal" ? "text-white" : "text-[#101828]"
                    }  text-xs text-center `}
                  >
                    Normal
                  </p>
                </div>

                <div
                  className={`${
                    Section === "VIP" ? "bg-[#B7C835]" : "bg-white"
                  } bg-white w-fit flex items-center px-4 py-2 lg:py-0 rounded-xl cursor-pointer`}
                  onClick={() => setSection("VIP")}
                >
                  <p
                    id="VIP"
                    className={`${
                      Section === "VIP" ? "text-white" : "text-[#101828]"
                    }  text-xs text-center `}
                  >
                    VIP
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Pangration */}

          <NormalPangration
            setOpenShowAccountDetails={setOpenShowAccountDetails}
            section={Section}
            itemsPerPage={8}
            Data={Accounts}
          />
          <VIPPangration
            setOpenShowAccountDetails={setOpenShowAccountDetails}
            section={Section}
            itemsPerPage={8}
            Data={Accounts}
          />
          <AllPangration
            setOpenShowAccountDetails={setOpenShowAccountDetails}
            section={Section}
            itemsPerPage={8}
            Data={Accounts}
          />

          {/* Drawer */}
          <div id="drawerBody" className=" hidden  ">
            <div
              id="drawer"
              className=" w-full bg-[#0D2135] opacity-80 h-full md:w-1/2"
            >
              <div className="p-4">
                <AiOutlineClose
                  className="text-xl text-white cursor-pointer "
                  onClick={() => closeDrawer()}
                />
              </div>
              <div className="flex flex-col ml-10 md:ml-20 space-y-1 w-full justify-center  h-4/5 text-white ">
                {/* border-b-2 border-[#847244] */}
                <Link
                  to="/"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center  text-xl  p-2 md:w-1/2 w-3/4"
                >
                  <div className="font-Poppins-Regular text-sm">Home</div>
                </Link>
                <Link
                  to="/Patients"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2 "
                >
                  <div className="font-Poppins-Regular text-sm">Patients </div>
                </Link>
                <Link
                  to="/Analytic"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Analytics</div>
                </Link>
                <Link
                  to="/Doctors"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Doctors</div>
                </Link>
                <Link
                  to="/Labs"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Labs </div>
                </Link>
                <Link
                  to="/Staff"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Staff</div>
                </Link>
                <Link
                  to="/Store"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Store</div>
                </Link>
                <Link
                  to="/Reports"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Reports</div>
                </Link>
                <Link
                  to="/"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Accounting</div>
                </Link>
                <Link
                  to="/Suppliers"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Suppliers</div>
                </Link>
                <Link
                  to="/Settings"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Setting</div>
                </Link>
                <Link
                  to="/"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Logout</div>
                </Link>
              </div>
            </div>
            <div className="hidden sm:block h-full w-full bg-black opacity-40" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
