import React, {useState, useEffect} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import NewsContainer from "../components/News/NewsContainer";
import {getNews} from '../Api/news';

function News() {
  const [news, setNews] = useState([])

  useEffect(() => {
    getNews(setNews);
  }, []);
  return (
    <div className="w-full h-full">
      <Header Section="News" />
      <div className="w-full h-full px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">News</p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">News</h1>
        </div>
        <div className="grid grid-cols-1  gap-y-5  lg:grid-cols-2 mt-10 pl-5 gap-x-5">
          <NewsContainer  Data={news} />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default News;
