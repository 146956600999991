/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CartContainer from "./CartContainer";

import {getInCart} from '../../Api/cart';
import useToken from '../../Api/Auth/useToken';

export default function Cart({ open, setOpen }) {
  const { token } = useToken();
  const cancelButtonRef = useRef(null);
  const [Method, SetMethod] = useState("NormalAccount");
  const [updateCart, setUpdateCart] = useState(false);

  const [data, setData] = useState(false);
  useEffect(()=>{
    getInCart(setData);
  }, [updateCart]);

  function close() {
    setOpen(false);
  }
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          initialFocus={cancelButtonRef}
          onClose={() => close()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto ">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-10 pb-8 bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:max-w-2xl sm:w-full ">
                  <div className="bg-white ">
                    <div className="w-full flex  h-full items-center mt-5">
                      <div className="flex flex-col items-start justify-start w-full space-y-2">
                        <p className="text-[#4A4A4A]  justify-start font-Roboto-Bold flex flex-grow text-2xl ">
                          Cart
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-5 w-full m-auto mt-10">
                      <div
                        className="
                         gap-5 grid grid-cols-1 "
                      >
                        
                        {token ? '' : 'you have to log in '}
                        {data && data.carts.map((item, key) => (
                          <CartContainer key={key} data={item} updateCart={updateCart} setUpdateCart={setUpdateCart}/>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bg-white    flex  justify-between mt-10">
                    <div className="flex items-center space-x-5">
                      <p className="font-Cairo-Regular text-xl">
                        total price:{" "}
                      </p>
                      <p className="font-Cairo-Regular text-[#93A750]">
                        ${data.sum}
                      </p>
                    </div>
                    <button
                      type="button"
                      className=" px-2 py-2 bg-gradient-to-r from-[#4A5428] to-[#B7C933] font-Lato-Bold  text-lg rounded-xl text-white"
                    >
                      Send request
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
