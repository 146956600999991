import React from "react";
import Pic2 from "../../Images/Pic2.svg";

function LastNewsContainer() {
  return (
    <div className="flex lg:flex-row flex-col border-[1px] rounded-xl w-full bg-white">
      <div className="flex flex-col space-y-1 lg:mb-0 mb-5 pr-3 flex-grow">
        <div className="w-full h-full flex justify-center mt-2 ">
          <h1 className="font-Cairo-Regular mt-2 px-5 text-[#828282] text-lg">
            10 Tips to Live Better With Psoriatic Arthritis
          </h1>
        </div>
        <div className="w-full h-full flex px-5 mt-2 ">
          <p className="font-Cairo-Regular  text-[#BBBBBB]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at
            dictum risus, non suscipit arcu. Quisque aliquam posuere tortor, sit
            amet convallis nunc scelerisque in.
          </p>
        </div>
      </div>
      <div className="flex items-center lg:flex-row flex-col  w-full lg:w-3/4 h-fit">
        <div className=" bg-[#B7C933] lg:mr-[-1.1rem] mb-[-1.2rem]  w-fit h-10 rotate-45">
          <p className=" w-10 text-sm  -rotate-45 text-center">18 Sep</p>
        </div>
        <div
          className="w-full h-52  bg-no-repeat lg:bg-cover flex-grow"
          style={{ backgroundImage: `url(${Pic2})` }}
        />
      </div>
    </div>
  );
}

export default LastNewsContainer;
