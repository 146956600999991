import axios from "axios";


export const getNews = async (set, setError) => {
    return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-news')
    .then(res => {
      set(res.data);
    })
    .catch(err => {
        // setError(err.response.data);
    }); 
}

export const getNewsDetails = async (id, set, setError) => {
    return await axios.get('https://aurora-team.com/labsWebsite/public/api/show-news/'+id)
    .then(res => {
      set(res.data);
    })
    .catch(err => {
        // setError(err.response.data);
    }); 
}

export const getImage = (img) => {
    return `https://aurora-team.com/labsWebsite/public/storage/${img}`;
}
