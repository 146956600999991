import React, { useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Clinical from "../../components/TestCatalogComponents/Overview/Clinical";
import Fees from "../../components/TestCatalogComponents/Overview/Fees";
import OverviewContainer from "../../components/TestCatalogComponents/Overview/OverviewContainer";
import Performance from "../../components/TestCatalogComponents/Overview/Performance";
import Setup from "../../components/TestCatalogComponents/Overview/Setup";
import Specimen from "../../components/TestCatalogComponents/Overview/Specimen";

function OverView() {
  const [section, setSection] = useState("Overview");
  return (
    <div className="w-full h-full">
      <Header Section="TestCatalog" />
      <div className="w-full h-full flex flex-col px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <Link
            to="/TestCatalog"
            className="font-Lato-Regular text-xl text-[#B7C933]"
          >
            Test Catalog
          </Link>
          <p className="font-Lato-Regular text-xl pl-5">{section}</p>
        </div>
        <div className="w-full mt-8">
          <h1 className="text-2xl font-Lato-Bold pb-8 border-b-[1px]">
            Test ID: <span className="font-Lato-Regular">VZIKM</span>
          </h1>
          <p className="font-Lato-Regular text-2xl w-full  mt-5 leading-relaxed">
            Antimicrobial Susceptibility, Mycobacterium tuberculosis Complex,
            First Line, Varies
          </p>

          <div className="w-full mt-10 flex flex-col lg:flex-row justify-around ">
            <div className="lg:w-1/3 w-3/4 mb-5 lg:mb-0 mx-auto  pr-8">
              <p
                className={`${
                  section === "Overview"
                    ? "bg-[#93A750] text-white pl-8"
                    : "bg-transparent text-black pl-1"
                } py-1 font-Lato-Regular border-b-[1px] border-t-[1px]  border-black cursor-pointer`}
                onClick={() => setSection("Overview")}
              >
                OVERVIEW
              </p>
              <p
                className={`${
                  section === "SPECIMEN"
                    ? "bg-[#93A750] text-white pl-8"
                    : "bg-transparent text-black pl-1"
                } py-1 font-Lato-Regular  border-b-[1px] border-black cursor-pointer`}
                onClick={() => setSection("SPECIMEN")}
              >
                SPECIMEN
              </p>
              <p
                className={`${
                  section === "CLINICAL & INTERPRETIVE"
                    ? "bg-[#93A750] text-white pl-8"
                    : "bg-transparent text-black pl-1"
                } py-1 font-Lato-Regular  border-b-[1px]  border-black cursor-pointer`}
                onClick={() => setSection("CLINICAL & INTERPRETIVE")}
              >
                CLINICAL & INTERPRETIVE
              </p>
              <p
                className={`${
                  section === "PERFORMANCE"
                    ? "bg-[#93A750] text-white pl-8"
                    : "bg-transparent text-black pl-1"
                } py-1 font-Lato-Regular  border-b-[1px] border-black cursor-pointer`}
                onClick={() => setSection("PERFORMANCE")}
              >
                PERFORMANCE
              </p>
              <p
                className={`${
                  section === "FEES & CODES"
                    ? "bg-[#93A750] text-white pl-8"
                    : "bg-transparent text-black pl-1"
                } py-1 font-Lato-Regular  border-b-[1px] border-black cursor-pointer`}
                onClick={() => setSection("FEES & CODES")}
              >
                FEES & CODES
              </p>
              <p
                className={`${
                  section === "SETUP & UPDATES"
                    ? "bg-[#93A750] text-white pl-8"
                    : "bg-transparent text-black pl-1"
                } py-1 font-Lato-Regular  border-b-[1px] border-black cursor-pointer`}
                onClick={() => setSection("SETUP & UPDATES")}
              >
                SETUP & UPDATES
              </p>
            </div>

            <div className="w-full h-full border-t-[1px] border-black">
              <OverviewContainer section={section} />
              <Specimen section={section} />
              <Clinical section={section} />
              <Performance section={section} />
              <Fees section={section} />
              <Setup section={section} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default OverView;
