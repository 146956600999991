import React, { useState , useEffect} from "react";
import Pic4 from "../../Images/Pic4.svg";
import Mines from "../../Images/Mines.svg";
import Plus from "../../Images/Plus.svg";

import {updateQuantity, deleteFromCart} from '../../Api/cart';
import useToken from '../../Api/Auth/useToken';

function CartContainer({data, updateCart, setUpdateCart}) {
  const { token } = useToken();
  const [Quantity, SetQuantity] = useState(data.pivot.quantity);
  const MinesFun = () => {
    if (Quantity > 1) {
      SetQuantity(Quantity - 1);
    }
  };
  const PlusFun = () => {
    SetQuantity(Quantity + 1);
  };
  useEffect(() => {
    if(Quantity == 0){
      deleteFromCart(data.id).then(() => {
        setUpdateCart(!updateCart)
      });
    }else{
      updateQuantity(data.id, Quantity).then(() => {
        setUpdateCart(!updateCart)
      });
    }
  }, [Quantity])
  return (
    <div className="flex w-full border-[1px] py-1 px-2 rounded-xl">
      <img src={Pic4} className="w-32" />
      <p className="font-Cairo-Regular h-full text-xl  ml-2 ">
        {data.name}
      </p>
      <p className="font-Cairo-Regular text-[#93A750] px-5">${data.price}</p>
      <div className="flex items-center border-[0.5px] w-52 justify-between rounded-xl  border-[#93A750] h-fit m-auto">
        <img src={Mines} onClick={() => MinesFun()} />
        <p className="text-[#93A750] px-3 font-Cairo-Regular ">{Quantity}</p>
        <img src={Plus} onClick={() => PlusFun()} />
      </div>
    </div>
  );
}

export default CartContainer;
