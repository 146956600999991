import axios from "axios";


export const contactUs = async (data, set) => {
    return await axios.post('https://aurora-team.com/labsWebsite/public/api/send-message', data)
    .then(res => {
      set(res.data.status);
    })
    .catch(err => {
        // setError(err.response.data);
    }); 
}
