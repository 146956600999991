/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import logo from "../../Images/Logo.svg";

export default function VIPNormal({ open, setOpen }) {
  const cancelButtonRef = useRef(null);
  const [Method, SetMethod] = useState("NormalAccount");

  function close() {
    setOpen(false);
  }
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          initialFocus={cancelButtonRef}
          onClose={() => close()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto ">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-10 pb-8 bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:max-w-xl sm:w-full ">
                  <div className="bg-white ">
                    <div className="w-full flex  h-full items-center mt-5">
                      <div className="flex flex-col items-center justify-center w-full space-y-2">
                        <img src={logo} className="w-fit h-fit" />

                        <p className="text-[#4A4A4A]  justify-center font-Roboto-Bold flex flex-grow text-2xl ">
                          Welcome Back!
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-5 w-full m-auto mt-10">
                      <div className="flex ">
                        <div
                          className={`${
                            Method === "NormalAccount"
                              ? "border-b-[#B7C933] "
                              : "border-b-[#777777]"
                          } flex border-b-2 p-2 w-full py-3  justify-center cursor-pointer `}
                          onClick={() => SetMethod("NormalAccount")}
                        >
                          <p
                            className={`${
                              Method === "NormalAccount"
                                ? "text-[#B7C933] font-Roboto-Bold"
                                : "text-[#777777] font-Roboto-Regular"
                            }     `}
                          >
                            normal account
                          </p>
                        </div>

                        <div
                          className={`${
                            Method === "VIPAccount"
                              ? "border-b-[#B7C933] "
                              : "border-b-[#777777]"
                          } flex border-b-2 p-2 w-full py-3  justify-center cursor-pointer `}
                          onClick={() => SetMethod("VIPAccount")}
                        >
                          <p
                            className={`${
                              Method === "VIPAccount"
                                ? "text-[#B7C933] font-Roboto-Bold"
                                : "text-[#777777] font-Roboto-Regular"
                            }     `}
                          >
                            VIP Leo account
                          </p>
                        </div>
                      </div>

                      <div
                        className="
                         gap-5 grid grid-cols-2 "
                      >
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="LaboratoryName"
                            placeholder="Laboratory name"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Number"
                            placeholder="Number"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Password"
                            placeholder="Password"
                            type="password"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Country"
                            placeholder="Country"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="City"
                            placeholder="City"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="TheAddress"
                            placeholder="The address"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                        <div className="w-full col-start-1 col-end-3 break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Email"
                            placeholder="Email"
                            type="email"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                        <div className="w-full col-start-1 col-end-3 break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="LaboratoryManager"
                            placeholder="The name of the laboratory manager"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bg-white    flex sm:flex-row-reverse justify-between mt-10">
                    <button
                      type="button"
                      className="flex flex-grow py-4 bg-gradient-to-r from-[#4A5428] to-[#B7C933] font-Roboto-Regular justify-center rounded-xl text-white"
                    >
                      LOG IN
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
