/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import logo from "../../Images/Logo.svg";

import useToken from '../../Api/Auth/useToken';
import {login, register} from '../../Api/Auth/Auth';


export default function LoginRegister({ open, setOpen }) {
  const { token, setToken } = useToken();

  const cancelButtonRef = useRef(null);
  const [Method, SetMethod] = useState("Register");

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [laboratoryName, setLaboratoryName] = useState('');
  const [number, setNumber] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [theAddress, setTheAddress] = useState('');
  const [laboratoryManager, setLaboratoryManager] = useState('');


  const [error, setError] = useState('');

  function close() {
    setOpen(false);
  }
  const submit = () => {
    const data = {};
    data['email'] = email;
    data['password'] = password;
    if(Method === 'Register'){
      data['lab_name'] = laboratoryName;
      data['phone'] = number;
      data['country'] = country;
      data['city'] = city;
      data['address'] = theAddress;
      data['manager_phone'] = laboratoryManager;
      register(data, setToken, setError).then(() => {
        window.location.reload();
      });
    }else if(Method === 'login'){
      login(data, setToken, setError).then(() => {
        window.location.reload();
      });
    }
  }
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          initialFocus={cancelButtonRef}
          onClose={() => close()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto ">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-10 pb-8 bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:max-w-xl sm:w-full ">
                  <div className="bg-white ">
                    <div className="w-full flex  h-full items-center mt-5">
                      <div className="flex flex-col items-center justify-center w-full space-y-2">
                        <img src={logo} className="w-fit h-fit" />

                        <p className="text-[#4A4A4A]  justify-center font-Roboto-Bold flex flex-grow text-2xl ">
                          Welcome Back!
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-5 w-full m-auto mt-10">
                      <div className="flex ">
                        <div
                          className={`${
                            Method === "Register"
                              ? "border-b-[#B7C933] "
                              : "border-b-[#777777]"
                          } flex border-b-2 p-2 w-full py-3  justify-center cursor-pointer `}
                          onClick={() => SetMethod("Register")}
                        >
                          <p
                            className={`${
                              Method === "Register"
                                ? "text-[#B7C933] font-Roboto-Bold text-xl"
                                : "text-[#777777] font-Roboto-Regular text-lg"
                            }     `}
                          >
                            Register
                          </p>
                        </div>

                        <div
                          className={`${
                            Method === "login"
                              ? "border-b-[#B7C933] "
                              : "border-b-[#777777]"
                          } flex border-b-2 p-2 w-full py-3  justify-center cursor-pointer `}
                          onClick={() => SetMethod("login")}
                        >
                          <p
                            className={`${
                              Method === "login"
                                ? "text-[#B7C933] font-Roboto-Bold text-xl"
                                : "text-[#777777] font-Roboto-Regular text-lg"
                            }     `}
                          >
                            login
                          </p>
                        </div>
                      </div>

                      <div
                        className={`${
                          Method === "Register" ? "grid" : "hidden"
                        } gap-5 grid grid-cols-2 `}
                      >
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="LaboratoryName"
                            placeholder="Laboratory name"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setLaboratoryName(e.target.value)}
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Number"
                            placeholder="Number"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setNumber(e.target.value)}
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Password"
                            placeholder="Password"
                            type="password"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Country"
                            placeholder="Country"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setCountry(e.target.value)}
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="City"
                            placeholder="City"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setCity(e.target.value)}
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="TheAddress"
                            placeholder="The address"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setTheAddress(e.target.value)}
                          />
                        </div>
                        <div className="w-full col-start-1 col-end-3 break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Email"
                            placeholder="Email"
                            type="email"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="w-full col-start-1 col-end-3 break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="LaboratoryManager"
                            placeholder="The name of the laboratory manager"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setLaboratoryManager(e.target.value)}
                          />
                        </div>
                        <div className="bg-white  flex flex-col col-start-1 col-end-3 mt-5">
                          <button
                            type="button"
                            onClick={submit}
                            className="flex flex-grow py-4 text-xl bg-gradient-to-r from-[#4A5428] to-[#B7C933] font-Roboto-Regular justify-center rounded-xl text-white"
                          >
                            Register
                          </button>
                          <p className="font-Roboto-Regular mx-auto text-[#474747] mt-5">
                            Already have an account?
                            <span
                              className="text-[#93A750] ml-2 cursor-pointer"
                              onClick={() => SetMethod("login")}
                            >
                              log in
                            </span>
                          </p>
                        </div>
                      </div>

                      <div
                        className={`${
                          Method === "login" ? "grid" : "hidden"
                        } gap-5 grid grid-cols-1 `}
                      >
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Username"
                            placeholder="Username"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Password"
                            placeholder="Password"
                            type="password"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setPassword(e.target.value)}
                          />
                        </div>

                        <div className="bg-white  flex flex-col  mt-5">
                          <button
                            onClick={submit}
                            type="button"
                            className="flex flex-grow py-4 text-xl bg-gradient-to-r from-[#4A5428] to-[#B7C933] font-Roboto-Regular justify-center rounded-xl text-white"
                          >
                            Log in
                          </button>
                          <p className="font-Roboto-Regular mx-auto text-[#474747] mt-5">
                            You do not have an account?
                            <span
                              className="text-[#93A750] ml-2 cursor-pointer"
                              onClick={() => SetMethod("Register")}
                            >
                              Register
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
