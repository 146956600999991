import "./App.css";
import Home from "./Pages/Home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TestCatalog from "./Pages/TestCatalog";
import NewTest from "./Pages/TestCatalogContain/NewTest";
import AllTest from "./Pages/TestCatalogContain/AllTest";
import TestRequest from "./Pages/TestCatalogContain/TestRequest";
import ResultsForm from "./Pages/TestCatalogContain/ResultsForm";
import SearchBar from "./Pages/TestCatalogContain/SearchBar";
import OverView from "./Pages/TestCatalogContain/OverView";
import System from "./Pages/System";
import Ordering from "./Pages/Ordering";
import AddTestSection from "./Pages/OrderingContainer/AddTestSection";
import TestPriceSection from "./Pages/OrderingContainer/TestPriceSection";
import Sample from "./Pages/Sample";
import BacterialSamples from "./Pages/SampleContainer/BacterialSamples";
import UrineSamples from "./Pages/SampleContainer/UrineSamples";
import Examinations from "./Pages/SampleContainer/Examinations";
import TransportSupplies from "./Pages/SampleContainer/TransportSupplies";
import News from "./Pages/News";
import CustomerService from "./Pages/CustomerService";
import NewsDetails from "./Pages/NewDetails";
import DashBoard from "./Pages/DashBoard";
import AnalysisDB from "./Pages/DashBoard/AnalysisDB";
import ProductDB from "./Pages/DashBoard/ProductDB";
import MessageDB from "./Pages/DashBoard/MessageDB";
import StoreDB from "./Pages/DashBoard/StoreDB";
import Profile from "./Pages/Profile";
import AccountSettings from "./Pages/Profile/AccountSettings";
import Invoices from "./Pages/Profile/Invoices";
import axios from "axios";
import useToken from './Api/Auth/useToken'
function App() {
  const {token} = useToken();
  if(token){
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  }

  return (
    <div className="w-full h-full">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/DashBoard" element={<DashBoard />} />
          <Route path="/DashBoard/Analysis" element={<AnalysisDB />} />
          <Route path="/DashBoard/Product" element={<ProductDB />} />
          <Route path="/DashBoard/Message" element={<MessageDB />} />
          <Route path="/DashBoard/Store" element={<StoreDB />} />

          <Route path="/System" element={<System />} />
          <Route path="/Profile" element={<Profile />} />
          <Route
            path="/Profile/AccountSettings"
            element={<AccountSettings />}
          />
          <Route path="/Profile/invoices" element={<Invoices />} />

          <Route path="/Ordering" element={<Ordering />} />
          <Route path="/Sample" element={<Sample />} />
          <Route path="/News" element={<News />} />
          <Route path="/News/NewsDetails/:id" element={<NewsDetails />} />
          <Route path="/CustomersService" element={<CustomerService />} />

          <Route
            path="/Sample/BacterialSamples"
            element={<BacterialSamples />}
          />
          <Route path="/Sample/UrineSamples" element={<UrineSamples />} />
          <Route path="/Sample/Examinations" element={<Examinations />} />
          <Route path="/Sample/Supplies" element={<TransportSupplies />} />
          <Route path="/Ordering/AddTest" element={<AddTestSection />} />
          <Route path="/Ordering/TestPrices" element={<TestPriceSection />} />
          <Route path="/TestCatalog" element={<TestCatalog />} />
          <Route path="/TestCatalog/NewTest" element={<NewTest />} />
          <Route path="/TestCatalog/AllTest" element={<AllTest />} />
          <Route path="/TestCatalog/TestRequest" element={<TestRequest />} />
          <Route path="/TestCatalog/ResultsForm" element={<ResultsForm />} />
          <Route path="/TestCatalog/SearchBar" element={<SearchBar />} />
          <Route path="/TestCatalog/OverView" element={<OverView />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
