import React from "react";
import { RiAccountBoxFill } from "react-icons/ri";
import { FaBox } from "react-icons/fa";
import { TbActivityHeartbeat } from "react-icons/tb";
import { HiBuildingStorefront } from "react-icons/hi2";
import { MdOutlineMessage } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { Link } from "react-router-dom";

import useToken from '../../Api/Auth/useToken';
import {logout} from '../../Api/Auth/Auth';

function SideBarDB({ page }) {
  const { token, setToken } = useToken();
  return (
    <div className="h-full pl-[2.20rem] hidden lg:block">
      <div className="bg-white rounded-xl w-fit py-5 px-1 mt-10">
        <Link to="/DashBoard" className="flex flex-col items-center ">
          <RiAccountBoxFill
            className={`w-6 h-6  ${
              page === "Accounts" ? "text-[#B7C835] " : "text-[#98A2B3]"
            }  `}
          />
          <p
            className={`${
              page === "Accounts" ? "text-[#B7C835]" : "text-[#98A2B3]"
            }  text-sm mt-1 font-Poppins-Regular `}
          >
            Accounts
          </p>
        </Link>
        <Link
          to="/DashBoard/Analysis"
          className="flex  flex-col items-center mt-5"
        >
          <TbActivityHeartbeat
            className={`w-6 h-6 ${
              page === "Analysis"
                ? "text-[#B7C835] border-[#B7C835]"
                : "text-[#98A2B3] border-[#98A2B3]"
            }  border-2 rounded-md`}
          />
          <p
            className={`${
              page === "Analysis" ? "text-[#B7C835]" : "text-[#98A2B3]"
            }  text-sm mt-1 font-Poppins-Regular`}
          >
            Analysis
          </p>
        </Link>
        <Link
          to="/DashBoard/Product"
          className="flex flex-col items-center mt-5"
        >
          <FaBox
            className={`w-6 h-6 ${
              page === "Product" ? "text-[#B7C835]" : "text-[#98A2B3]"
            }  `}
          />
          <p
            className={`${
              page === "Product" ? "text-[#B7C835]" : "text-[#98A2B3]"
            } text-sm mt-1 font-Poppins-Regular`}
          >
            Product
          </p>
        </Link>
        <Link to="/DashBoard/Store" className="flex flex-col items-center mt-5">
          <HiBuildingStorefront
            className={`w-6 h-6 ${
              page === "Store" ? "text-[#B7C835]" : "text-[#98A2B3]"
            }`}
          />
          <p
            className={`${
              page === "Store" ? "text-[#B7C835]" : "text-[#98A2B3]"
            }  text-sm mt-1 font-Poppins-Regular`}
          >
            Store
          </p>
        </Link>
        <Link
          to="/DashBoard/Message"
          className="flex flex-col items-center mt-5"
        >
          <MdOutlineMessage
            className={`w-6 h-6  ${
              page === "Message" ? "text-[#B7C835]" : "text-[#98A2B3]"
            }`}
          />
          <p
            className={`${
              page === "Message" ? "text-[#B7C835]" : "text-[#98A2B3]"
            } text-sm mt-1 font-Poppins-Regular`}
          >
            Messages
          </p>
        </Link>
        <div className="flex flex-col items-center mt-28 cursor-pointer"
            onClick={() => logout(setToken)}
          >
          <CiLogout className="w-6 h-6  text-[#F04438]" />
          <p className="text-[#F04438] text-sm mt-1  font-Poppins-Regular">
            Logout
          </p>
        </div>
      </div>
    </div>
  );
}

export default SideBarDB;
