import React, { useState } from "react";
import logo from "../Images/Logo.svg";
import UkFlag from "../Images/UkFlag.svg";
import { IoIosArrowDown } from "react-icons/io";
import VIPNormal from "./Header/VIPNormal";
import LoginRegister from "./Header/LoginRegister";
import { Link } from "react-router-dom";
import { VscListFlat } from "react-icons/vsc";
import { AiOutlineClose } from "react-icons/ai";

import useToken from '../Api/Auth/useToken';
import {logout} from '../Api/Auth/Auth';

function Header({ Section }) {
  const [OpenVIPNormal, setOpenVIPNormal] = useState(false);
  const [OpenLoginRegister, setOpenLoginRegister] = useState(false);
  const [DrawerNot, SetDrawerNot] = useState();

  const { token, setToken } = useToken();

  function drawerHeader() {
    document.getElementById("drawerHeaderBody").classList.remove("hidden");
    document
      .getElementById("drawerHeaderBody")
      .classList.add(
        "flex",
        "fixed",
        "h-screen",
        "w-full",
        "bg-transparent",
        "top-0",
        "right-0",
        "lg:hidden",
        "z-10"
      );
  }

  function drawerNoti() {
    document.getElementById("drawerNotiBody").classList.remove("hidden");
    document
      .getElementById("drawerNotiBody")
      .classList.add(
        "flex",
        "w-full",
        "bg-transparent",
        "lg:hidden",
        "z-10",
        "flex-row-reverse"
      );
    document.getElementById("drawerNotibtn").classList.add("hidden");
    SetDrawerNot(false);
  }

  function closeDrawerHeader() {
    document.getElementById("drawerHeaderBody").classList.add("hidden");
  }

  function closeDrawerNoti() {
    document.getElementById("drawerNotiBody").classList.add("hidden");
    document.getElementById("drawerNotibtn").classList.remove("hidden");
    SetDrawerNot(true);
  }
  return (
    <div className="w-full flex flex-col  ">
      <VIPNormal open={OpenVIPNormal} setOpen={setOpenVIPNormal} />
      <LoginRegister open={OpenLoginRegister} setOpen={setOpenLoginRegister} />

      <div className="w-full flex justify-between items-center py-2 px-5 lg:px-20 bg-white">
        <img src={logo} className="w-fit h-fit" />
        <div className="hidden lg:flex space-x-5">
        {token ? (
          <p
            className="font-Lato-Regular cursor-pointer"
            onClick={() => logout(setToken)}
          >
            Logout
          </p>
        ) : (
          <p
            className="font-Lato-Regular cursor-pointer"
            onClick={() => setOpenLoginRegister(true)}
          >
            Log in/ Register
          </p>
        )}
          <p
            className="font-Lato-Regular cursor-pointer"
            onClick={() => setOpenVIPNormal(true)}
          >
            VIP Leo/ Normal
          </p>
          <Link to="/DashBoard" className="font-Lato-Regular cursor-pointer">
            My Dashboard
          </Link>
          <div className="flex space-x-2 items-center cursor-pointer">
            <img src={UkFlag} className="w-fit h-fit" />
            <IoIosArrowDown className="text-[#A2ADBC]" />
          </div>
        </div>
        <div className="bg-white lg:mr-0 lg:hidden rounded-l-xl ">
          <VscListFlat
            id="drawerHeaderbtn"
            className="text-black  text-xl m-2  cursor-pointer w-10 h-20  "
            onClick={() => drawerHeader()}
          />
        </div>
      </div>

      <div className="flex lg:justify-start justify-end bg-[#0F2135]">
        <div className="hidden lg:flex px-20 space-x-5 py-3">
          <Link to="/" className="w-fit">
            <p className="text-white font-Lato-Regular text-xl">Home</p>
            <hr
              className={`${Section === "Home" ? "block" : "hidden"}
               w-3/4 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
            />
          </Link>
          <Link to="/TestCatalog" className="w-fit">
            <p className="text-white font-Lato-Regular text-xl">Test catalog</p>
            <hr
              className={`${
                Section === "TestCatalog" ? "block" : "hidden"
              } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
            />
          </Link>
          <Link to="/System" className="w-fit">
            <p className="text-white font-Lato-Regular text-xl">System</p>
            <hr
              className={`${
                Section === "System" ? "block" : "hidden"
              } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
            />
          </Link>
          <Link to="/Ordering" className="w-fit">
            <p className="text-white font-Lato-Regular text-xl">
              Ordering & Results
            </p>
            <hr
              className={`${
                Section === "Ordering" ? "block" : "hidden"
              } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
            />
          </Link>
          <Link to="/Sample" className="w-fit">
            <p className="text-white font-Lato-Regular text-xl">Sample</p>
            <hr
              className={`${
                Section === "Sample" ? "block" : "hidden"
              } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
            />
          </Link>
          <Link to="/CustomersService" className="w-fit">
            <p className="text-white font-Lato-Regular text-xl">
              customers service
            </p>
            <hr
              className={`${
                Section === "CustomerService" ? "block" : "hidden"
              } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
            />
          </Link>
          <Link to="/News" className="w-fit">
            <p className="text-white font-Lato-Regular text-xl">News</p>
            <hr
              className={`${
                Section === "News" ? "block" : "hidden"
              } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
            />
          </Link>
        </div>
        <div
          id="drawerNotibtn"
          className={`bg-transparent mr-5  lg:hidden rounded-l-xl `}
        >
          <VscListFlat
            className="text-white  text-xl m-2  cursor-pointer w-10 h-20  "
            onClick={() => drawerNoti()}
          />
        </div>
        {/* Noti Drawer */}
        <div id="drawerNotiBody" className="hidden w-full bg-[#0D2135]   ">
          <div className="p-4">
            <AiOutlineClose
              className="text-xl mr-5 mt-5 text-white cursor-pointer "
              onClick={() => closeDrawerNoti()}
            />
          </div>
          <div className="flex flex-col mt-10 space-y-3 pb-5 items-center  w-full  text-white ">
            {/* border-b-2 border-[#847244] */}
            <Link to="/" className="w-fit">
              <p className="text-white font-Lato-Regular text-xl">Home</p>
              <hr
                className={`${Section === "Home" ? "block" : "hidden"}
               w-3/4 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
              />
            </Link>
            <Link to="/TestCatalog" className="w-fit">
              <p className="text-white font-Lato-Regular text-xl">
                Test catalog
              </p>
              <hr
                className={`${
                  Section === "TestCatalog" ? "block" : "hidden"
                } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
              />
            </Link>
            <Link to="/System" className="w-fit">
              <p className="text-white font-Lato-Regular text-xl">System</p>
              <hr
                className={`${
                  Section === "System" ? "block" : "hidden"
                } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
              />
            </Link>
            <Link to="/Ordering" className="w-fit">
              <p className="text-white font-Lato-Regular text-xl">
                Ordering & Results
              </p>
              <hr
                className={`${
                  Section === "Ordering" ? "block" : "hidden"
                } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
              />
            </Link>
            <Link to="/Sample" className="w-fit">
              <p className="text-white font-Lato-Regular text-xl">Sample</p>
              <hr
                className={`${
                  Section === "Sample" ? "block" : "hidden"
                } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
              />
            </Link>
            <Link to="/CustomersService" className="w-fit">
              <p className="text-white font-Lato-Regular text-xl">
                customers service
              </p>
              <hr
                className={`${
                  Section === "CustomerService" ? "block" : "hidden"
                } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
              />
            </Link>
            <Link to="/News" className="w-fit">
              <p className="text-white font-Lato-Regular text-xl">News</p>
              <hr
                className={`${
                  Section === "News" ? "block" : "hidden"
                } w-2/5 border-2 rounded-full mt-[0.10rem] border-[#B7C935]`}
              />
            </Link>
          </div>
        </div>
      </div>

      {/* Drawer */}
      <div id="drawerHeaderBody" className=" hidden ">
        <div
          id="drawer"
          className=" w-full bg-[#0D2135] opacity-80 h-full md:w-1/2"
        >
          <div className="p-4">
            <AiOutlineClose
              className="text-xl text-white cursor-pointer "
              onClick={() => closeDrawerHeader()}
            />
          </div>
          <div className="flex flex-col items-center  sm:ml-20 sm:items-start space-y-10 md:ml-20  w-full justify-center  h-4/5 text-white ">
            {/* border-b-2 border-[#847244] */}
            <p
              className="font-Lato-Regular cursor-pointer"
              onClick={() => setOpenLoginRegister(true)}
            >
              Log in/ Register
            </p>
            <p
              className="font-Lato-Regular cursor-pointer"
              onClick={() => setOpenVIPNormal(true)}
            >
              VIP Leo/ Normal
            </p>
            <Link to="/DashBoard" className="font-Lato-Regular cursor-pointer">
              My Dashboard
            </Link>
            <div className="flex space-x-2 items-center cursor-pointer">
              <img src={UkFlag} className="w-fit h-fit" />
              <IoIosArrowDown className="text-[#A2ADBC]" />
            </div>{" "}
          </div>
        </div>
        <div className="hidden sm:block h-full w-full bg-black opacity-40" />
      </div>
    </div>
  );
}

export default Header;
