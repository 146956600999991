import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import SampleCollectionIcon from "../Images/SampleCollectionIcon.svg";
import TransferBacteriaSampleIcon from "../Images/TransferBacteriaSampleIcon.svg";
import InstructionsIcon from "../Images/InstructionsIcon.svg";
import ExaminationIcon from "../Images/ExaminationIcon.svg";
import TransportationGuideIcon from "../Images/TransportationGuideIcon.svg";
import CDCIcon from "../Images/CDCIcon.svg";
import CourierInstructionsIcon from "../Images/CourierInstructionsIcon.svg";
import InfectiousSubstancesIcon from "../Images/InfectiousSubstancesIcon.svg";
import CollectingTransportingIcon from "../Images/CollectingTransportingIcon.svg";

function Sample() {
  return (
    <div className="w-full h-full">
      <Header Section="Sample" />
      <div className="w-full h-full px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">Sample</p>
        </div>
      </div>

      <div className="w-full mt-10 px-20 ">
        <h1 className="text-2xl font-Lato-Bold">
          Sample collection and transportation
        </h1>
      </div>

      <div className="grid  px-8 lg:px-20 grid-cols-1 lg:grid-cols-2 mt-10  gap-y-5 gap-x-5">
        <Link
          to="/Sample/Supplies"
          className="flex space-x-4 border-[1px] w-full p-5 items-center rounded-xl"
        >
          <img src={SampleCollectionIcon} className="w-20" />
          <div className="flex flex-col">
            <p className="text-xl font-Lato-Bold mb-1 w-1/2">
              Sample collection and transport supplies
            </p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </Link>

        <Link
          to="/Sample/BacterialSamples"
          className="flex space-x-4 border-[1px] p-5 rounded-xl"
        >
          <img src={TransferBacteriaSampleIcon} className="w-20" />
          <div className="flex flex-col ">
            <p className="text-xl font-Lato-Bold mb-1 w-1/2">
              Collection and transfer of bacterial samples
            </p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </Link>

        <Link
          to="/Sample/UrineSamples"
          className="flex space-x-4 border-[1px] p-5 rounded-xl"
        >
          <img src={CollectingTransportingIcon} className="w-20" />
          <div className="flex flex-col ">
            <p className="text-xl font-Lato-Bold mb-1 w-3/4">
              Collecting and transporting urine samples
            </p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </Link>

        <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
          <img src={InstructionsIcon} className="w-20" />
          <div className="flex flex-col ">
            <p className="text-xl font-Lato-Bold mb-1 w-1/2">
              Instructions according to sample type
            </p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </div>

        <Link
          to="/Sample/Examinations"
          className="flex space-x-4 border-[1px] p-5 rounded-xl"
        >
          <img src={ExaminationIcon} className="w-20" />
          <div className="flex flex-col ">
            <p className="text-xl font-Lato-Bold mb-1 w-1/2">
              Examinations that require protection from light
            </p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </Link>

        <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
          <img src={TransportationGuideIcon} className="w-20" />
          <div className="flex flex-col ">
            <p className="text-xl font-Lato-Bold mb-1 w-1/2">
              Sample collection and transportation guide
            </p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </div>

        <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
          <img src={CDCIcon} className="w-20" />
          <div className="flex flex-col ">
            <p className="text-xl font-Lato-Bold mb-1 w-1/2">CDC statement</p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </div>

        <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
          <img src={CourierInstructionsIcon} className="w-20" />
          <div className="flex flex-col ">
            <p className="text-xl font-Lato-Bold mb-1 w-1/2">
              Courier instructions
            </p>
            <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              neque quam
            </p>
          </div>
        </div>

        <div className="flex space-x-4  lg:col-start-1 lg:col-end-3 w-full justify-center ">
          <div className="flex space-x-4 border-[1px] p-5 rounded-xl lg:w-1/2">
            <img src={InfectiousSubstancesIcon} className="w-20" />
            <div className="flex flex-col ">
              <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                Infectious substances
              </p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                neque quam
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Sample;
