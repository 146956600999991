import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

function Footer() {
  return (
    <div className="flex w-full bg-[#0F2135] mt-10 py-5 relative bottom-0">
      <div className="flex items-center w-full justify-between px-20">
        <p className="text-white font-Lato-Regular">Copyright 2022 Lap peo.</p>
        <div className="flex space-x-5">
          <div className="p-2 border-[1px] border-gray-500 rounded-full cursor-pointer">
            <BsWhatsapp className="text-white text-lg" />
          </div>
          <div className="p-2 border-[1px] border-gray-500 rounded-full cursor-pointer">
            <BsInstagram className="text-white text-lg" />
          </div>

          <div className="p-2 border-[1px] border-gray-500 rounded-full cursor-pointer">
            <FaFacebookF className="text-white text-lg" />
          </div>

          <div className="p-2 border-[1px] border-gray-500 rounded-full cursor-pointer">
            <BsTwitter className="text-white text-lg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
