import React from "react";
import { Link } from "react-router-dom";
import {getImage} from '../../Api/news';
import { format } from 'date-fns'

function NewsContainer({ Data }) {
  return (
    Data.map((item) => (
        <Link
          to={"/News/NewsDetails/" + item.id}
          className="gap-x-3 grid grid-cols-2 border-[1px] rounded-xl w-full"
        >
          <div className="flex items-center w-full h-full">
            <div
              className="w-full h-full  bg-cover"
              style={{ backgroundImage: `url(${getImage(item.image)})` }}
            />
            <div className=" bg-[#B7C933] ml-[-1.1rem]  w-fit h-10 rotate-45">
              <p className=" w-10 text-sm  -rotate-45 text-center">
                {format(new Date(item.date), 'd MMM')}
              </p>
            </div>
          </div>
          <div className="flex flex-col space-y-1 pr-3 ">
            <div className="w-full h-full ">
              <h1 className="font-Cairo-Regular mt-2 text-[#828282] text-lg">
                {item.title}
              </h1>
            </div>
            <p className="font-Cairo-Regular pb-2 text-[#BBBBBB]">
              {item.description}
            </p>
          </div>
        </Link>
      )
    )
  )
}

export default NewsContainer;
