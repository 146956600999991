import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import SendRequestIcon from "../../../Images/TestCatalog/SendRequestIcon.svg";
import LockIcon from "../../../Images/LockIcon.svg";
import SendRequestContainer from "./SendRequestContainer";
import useToken from '../../../Api/Auth/useToken';
import PriceError from "../../Ordering/PriceError";

function ListTestRequest({ Data }) {
  const [OpenSendRequest, setOpenSendRequest] = useState(false);
  const [testId, setTestId] = useState(0);
  const { token, setToken } = useToken();
  const [OpenError, setOpenError] = useState(false);

  return (
    <div className="w-full h-full mt-10">
      <PriceError open={OpenError} setOpen={setOpenError} />
      <SendRequestContainer
        open={OpenSendRequest}
        setOpen={setOpenSendRequest}
        testId={testId}
      />
      <div className="w-1/2 mb-5 break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center   border-[1px] rounded-xl ">
        <input
          name="Find analytics"
          placeholder="Find analytics"
          type="text"
          className="w-full pl-2 bg-white font-Roboto-Regular text-sm text-[#707070] outline-0 ring-0"
        />
        <FiSearch className="bg-[#B7C933] h-full w-11 text-white p-2 rounded-r-xl cursor-pointer" />
      </div>
      <table className="w-full h-full  bg-white    ">
        <tr className="bg-[#93A750] w-full border-b-[1px] border-[#707070]">
          <td className="text-lg text-white p-5 rounded-tl-lg font-Lato-Bold py-3 pl-8 ">
            Test ID
          </td>
          <td className="text-lg text-white p-5 border-l-[1px] border-[#707070] font-Lato-Bold py-1 ">
            Test Name
          </td>
          <td className="text-lg text-white p-5 border-l-[1px] text-center border-[#707070]  font-Lato-Bold py-1 ">
            Published
          </td>
          <td className="text-lg text-white p-5 border-l-[1px] text-center border-[#707070] rounded-tr-lg font-Lato-Bold py-1 ">
            Test request
          </td>
        </tr>
        {Data.map((item) => (
          <tr className=" w-full border-b-[1px] border-[#707070]">
            <td className=" text-[#707070] p-5  rounded-tl-lg border-l-[1px] border-[#707070] font-Lato-Bold py-1 pl-8 ">
              {item.TestID}
            </td>
            <td className=" text-[#707070] p-5   border-l-[1px] border-[#707070] font-Lato-Bold py-1 ">
              {item.TestName}
            </td>
            <td className=" text-[#707070] p-5 text-center border-x-[1px] border-[#707070] rounded-tr-lg font-Lato-Bold py-1 ">
              {item.publish_date}
            </td>
            <td className=" text-[#707070] p-5 border-x-[1px] border-[#707070]  rounded-tr-lg font-Lato-Bold py-1 ">
            {token ? (
              <img
                src={SendRequestIcon}
                className="mx-auto cursor-pointer"
                onClick={() => {
                  setOpenSendRequest(true);
                  setTestId(item.id);
                }
              }
              />
            ) : (
              <img
                src={LockIcon}
                className="mx-auto cursor-pointer"
                onClick={() => {
                  setOpenError(true);
                }
              }
              />
            )}

            </td>
          </tr>
        ))}
      </table>
    </div>
  );
}

export default ListTestRequest;
