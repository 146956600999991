import React from "react";
import Pic4 from "../../Images/Pic4.svg";
import useToken from '../../Api/Auth/useToken';
import {addToCart} from '../../Api/cart';
function CardSupplier({Data}) {
  const { token } = useToken();
  const add = () => {
    addToCart(Data.id).then(() => {
      alert('done');
    })
  }
  return (
    <div className="px-2 py-2 pb-5 w-3/4 space-y-2 border-[1px] rounded-xl">
      <img src={Pic4} />
      <h1 className="font-Cairo-Regular text-[#828282]">
        {Data.name}
      </h1>
      <p className="text-[#BBBBBB] font-Cairo-Regular">
        {Data.description}
      </p>
      <div className="flex justify-between items-center px-1">
        <div className="bg-gradient-to-r from-[#93A750] to-[#B7C933] py-1 px-8 rounded-lg cursor-pointer"
        onClick={add}>
          <p className="font-Lato-Bold text-lg text-white">Add</p>
        </div>
        <p className="text-[#93A750] font-Cairo-Regular">${Data.price}</p>
      </div>
    </div>
  );
}

export default CardSupplier;
