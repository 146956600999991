import React from "react";
import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

function SearchBar() {
  const tests = [
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
  ];
  return (
    <div className="w-full h-full">
      <Header Section="TestCatalog" />
      <div className="w-full h-full flex flex-col px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <Link
            to="/TestCatalog"
            className="font-Lato-Regular text-xl text-[#B7C933]"
          >
            Test Catalog
          </Link>
          <p className="font-Lato-Regular text-xl pl-5">Search Bar</p>
        </div>
        <div className="w-full mt-10 space-y-10 pb-[5.6rem]">
          <h1 className="text-2xl font-Lato-Bold">Search Bar</h1>
          <div className="lg:w-1/2 w-3/4 mb-5 break-words border-[#E4E7EC] h-fit bg-white flex space-x-2 items-center py-4    border-[1px] rounded-xl ">
            <input
              name="Search"
              placeholder="Search....."
              type="text"
              className="w-full pl-2 bg-white font-Roboto-Regular text-base text-[#707070] outline-0 ring-0"
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SearchBar;
