import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AboutSystemImage from "../Images/AboutSystemImage.svg";
import ServicesImgaeVideo from "../Images/ServicesImgaeVideo.svg";
import Visa from "../Images/Visa.svg";
import PayPal from "../Images/PayPal.svg";
import MasterCard from "../Images/MasterCard.svg";
import AmericanExpress from "../Images/AmericanExpress.svg";
import { Link } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";

function System() {
  return (
    <div className="w-full h-full">
      <Header Section="System" />
      <div className="w-full h-full px-20 mt-10  mb-20">
        <div className="w-full flex  space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">System</p>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between lg:pr-24 mt-10">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex flex-col justify-center  items-center lg:items-start lg:w-full lg:pl-20">
            <p className="font-Lato-Bold text-3xl">About the system</p>
            <hr className="border-2 border-[#B7C933] w-1/4 mt-2" />
          </div>
          <div className="flex flex-col  items-center mb-20 lg:mb-0">
            <p className="font-Lato-Bold text-2xl w-[75%] leading-relaxed mt-5">
              We Are The Trusted Experts We Keep Things Simple
            </p>
            <p className="text-[#707070] mt-5 w-[75%] text-xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at
              dictum risus, non suscipit arcu. Quisque aliquam posuere tortor,
              sit amet convallis nunc scelerisque in.
            </p>
            <p className="text-[#707070] mt-10 w-[75%] text-xl">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at
              dictum risus, non suscipit arcu. Quisque aliquam posuere tortor,
              sit amet convallis nunc scelerisque in.
            </p>
            <div className="bg-[#93A750] w-fit py-4 px-10 rounded-xl mt-14 border-2">
              <p className="text-xl  font-Lato-Bold text-white">More About</p>
            </div>
          </div>
        </div>
        <img src={AboutSystemImage} />
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between lg:pr-24 mt-10">
        <img src={ServicesImgaeVideo} />
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex flex-col justify-center items-center lg:items-start lg:w-fit">
            <p className="font-Lato-Bold text-3xl">Services</p>
            <hr className="border-2 border-[#B7C933] w-1/3 mt-2" />
          </div>
          <div className="flex flex-col items-start mb-10 lg:mb-0">
            <p className="text-xl font-Lato-Bold ml-2 lg:ml-0 w-[70%] mt-7">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </p>
            <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-10">
              <GoPrimitiveDot className="text-2xl text-[#93A750]" />
              <p className="font-Lato-Regular text-[#707070] text-2xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
            </div>
            <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
              <GoPrimitiveDot className="text-2xl text-[#93A750]" />
              <p className="font-Lato-Regular text-[#707070] text-2xl">
                Lorem ipsum dolor sit amet
              </p>
            </div>
            <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
              <GoPrimitiveDot className="text-2xl text-[#93A750]" />
              <p className="font-Lato-Regular text-[#707070] text-2xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </p>
            </div>
            <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
              <GoPrimitiveDot className="text-2xl text-[#93A750]" />
              <p className="font-Lato-Regular text-[#707070] text-2xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing
              </p>
            </div>
            <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
              <GoPrimitiveDot className="text-2xl text-[#93A750]" />
              <p className="font-Lato-Regular text-[#707070] text-2xl">
                Lorem ipsum dolor sit amet
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full ">
        <div className="w-fit pl-16">
          <p className="font-Lato-Bold text-3xl">Payment methods</p>
          <hr className="border-2 border-[#B7C933] w-1/5 mt-2" />
        </div>
        <div className="w-full grid grid-cols-1 items-center justify-center md:grid-cols-2 lg:grid-cols-4 px-16 mt-8  bg-[#93A750]">
          <img src={Visa} className="mt-5 lg:mt-0 mx-auto" />
          <img src={PayPal} className="mt-5 lg:mt-0 mx-auto" />
          <img src={MasterCard} className="mt-5 lg:mt-0 mx-auto" />
          <img src={AmericanExpress} className="mx-auto" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default System;
