import React, {useState, useEffect} from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import NewsDetailsContainer from "../components/News/NewsDetailsContainer";

import {useParams} from "react-router-dom";
import {getNewsDetails} from '../Api/news';
function NewsDetails() {
  const [details, setDetails] = useState(false);
  let { id } = useParams();
  useEffect(() => {
    getNewsDetails(id, setDetails);
  }, []);
  return (
    <div className="w-full h-full">
      <Header Section="News" />
      <div className="w-full h-full px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">News</p>
        </div>
        {
          details ? (
            <>
              <div className="w-full mt-10">
                <h1 className="text-2xl font-Lato-Bold">{details.title}</h1>
              </div>
              <NewsDetailsContainer data={details}/>
            </>
            ) : ''
        }

      </div>
      <Footer />
    </div>
  );
}

export default NewsDetails;
