import React from "react";
import Pic4 from "../../Images/Pic4.svg";
import Share from "../../Images/Share.svg";
import { format } from 'date-fns'
import {
  BsFillSquareFill,
  BsInstagram,
  BsSquareFill,
  BsTwitter,
  BsWhatsapp,
} from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

import {getImage} from '../../Api/news';

function NewsDetailsContainer({ data }) {
  return (
    <div className="mt-10">
      <div className="flex lg:flex-row flex-col space-y-5 lg:space-y-0  w-full lg:space-x-5 ">
        <div className="flex flex-col  w-full ">
          <img src={getImage(data.image)} className="w-full h-full" />
          <div className="flex space-x-5 mt-3">
            <h1 className="font-Lato-Regular  text-black">
              Posted On:{" "}
              <span className="text-sm ml-2 text-[#A2A2A2]">
                {" "}
                {format(new Date(data.created_at), 'MMMM d, yyyy')}
              </span>
            </h1>
            <h1 className="font-Lato-Regular text-black">
              Posted By:{" "}
              <span className="text-sm ml-2 text-[#A2A2A2]">{data.posted_by}</span>
            </h1>
          </div>
        </div>
        <div className="h-fit p-5 border-[1px] rounded-lg">
          <h1 className="font-Lato-Bold text-xl pb-2 border-b-[1px]">
            Popular Posts
          </h1>
          <hr className="w-20 border-[#B7C933] " />
          <div className="flex flex-col space-y-3 ">
            <div className="flex space-x-3 mt-5">
              <img src={Pic4} className="w-20" />
              <div className="flex flex-col">
                <p className="font-Lato-Regular text-[#A2A2A2] text-sm">
                  JUNE 30, 2021
                </p>
                <h1 className="font-Lato-Regular text-[#0F2135]">
                  How to change yourself for the better
                </h1>
              </div>
            </div>

            <div className="flex  space-x-3">
              <img src={Pic4} className="w-20" />
              <div className="flex flex-col">
                <p className="font-Lato-Regular text-[#A2A2A2] text-sm">
                  JUNE 30, 2021
                </p>
                <h1 className="font-Lato-Regular text-[#0F2135]">
                  How to change yourself for the better
                </h1>
              </div>
            </div>
            <div className="flex  space-x-3">
              <img src={Pic4} className="w-20" />
              <div className="flex flex-col">
                <p className="font-Lato-Regular text-[#A2A2A2] text-sm">
                  JUNE 30, 2021
                </p>
                <h1 className="font-Lato-Regular text-[#0F2135]">
                  How to change yourself for the better
                </h1>
              </div>
            </div>
            <div className="flex  space-x-3">
              <img src={Pic4} className="w-20" />
              <div className="flex flex-col">
                <p className="font-Lato-Regular text-[#A2A2A2] text-sm">
                  JUNE 30, 2021
                </p>
                <h1 className="font-Lato-Regular text-[#0F2135]">
                  How to change yourself for the better
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:w-3/4 w-full mt-10 ">
        <h1 className="font-Lato-Bold text-xl mb-5">
          250+ Medical Tips We Just Had to Share
        </h1>
        <p className="font-Lato-Regular text-[#BBBBBB] text-lg">
          Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque
          porro quia non numquam eius modi tempora incidunt ut labore et dolore
          magnam dolor sit amet, consectetur adipisicing.
        </p>
        <p className="mt-10 font-Lato-Regular text-[#BBBBBB] text-lg">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in sed quia non numquam eius modi tempora incidunt ut
          labore et dolore magnam aliquam quaerat voluptatem.
        </p>
        <div className="grid grid-cols-3 gap-5 my-5">
          {
            data.images.map((item) => (
              <img src={getImage(item.image)} />
            ))
          }
          {/* <img src={Pic4} />
          <img src={Pic4} />
          <img src={Pic4} /> */}
        </div>
        <h1 className="font-Lato-Bold text-xl mb-5">
          Four major elements that we offer:
        </h1>
        <div className="flex font-Lato-Regular text-lg items-center space-x-2">
          <BsSquareFill className="text-xs text-[#B7C933]" />
          <p>Scientific Skills For getting a better result</p>
        </div>
        <div className="flex font-Lato-Regular text-lg items-center space-x-2">
          <BsSquareFill className="text-xs text-[#B7C933]" />
          <p>Communication Skills to getting in touch</p>
        </div>
        <div className="flex font-Lato-Regular text-lg items-center space-x-2">
          <BsSquareFill className="text-xs text-[#B7C933]" />
          <p>A Career Overview opportunity Availables</p>
        </div>
        <div className="flex font-Lato-Regular text-lg items-center space-x-2">
          <BsSquareFill className="text-xs text-[#B7C933]" />
          <p>A good Work Environment For work</p>
        </div>
        <h1 className="font-Lato-Bold text-xl my-5">
          Setting the mood with incense
        </h1>
        <p className="font-Cairo-Regular text-[#BBBBBB] text-lg">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in sed quia non numquam eius modi tempora incidunt ut
          labore et dolore magnam aliquam quaerat voluptatem.
        </p>
      </div>

      <div className="w-full ">
        <div className="flex space-x-2 items-center justify-center mt-5">
          <div className="flex items-center space-x-2">
            <img src={Share} className="w-5" />
            <p>Share :</p>
          </div>
          <div className="p-2 border-[1px] rounded-full cursor-pointer">
            <BsWhatsapp className="text-[#93A750] text-xl" />
          </div>
          <div className="p-2 border-[1px] rounded-full cursor-pointer">
            <BsInstagram className="text-[#93A750] text-xl" />
          </div>

          <div className="p-2 border-[1px] rounded-full cursor-pointer">
            <FaFacebookF className="text-[#93A750] text-xl" />
          </div>

          <div className="p-2 border-[1px] rounded-full cursor-pointer">
            <BsTwitter className="text-[#93A750] text-xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetailsContainer;
