import axios from "axios";

export const getProducts = async (set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-all-products')
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}

export const getInCart = async (set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-in-cart')
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}

export const addToCart = async (product_id) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/add-to-cart', {product_id: product_id})
}

export const updateQuantity = async (id, quantity) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/update-quantity/'+ id, {quantity: quantity})
}


export const deleteFromCart = async (id, quantity) => {
  return await axios.delete('https://aurora-team.com/labsWebsite/public/api/delete-from-cart/'+ id)
}
