import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import ContactUsImg from "../Images/ContactUsImg.svg";
import LocationIcon from "../Images/LocationIcon.svg";
import EmailIcon from "../Images/EmailIcon.svg";
import PhoneIcon from "../Images/PhoneIcon.svg";

import { useState } from "react";
import {contactUs} from '../Api/contactUs';

function CustomerService() {
  const [response, setResponse] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const sendMessage = () => {
    const data = {};
    data['name'] = name;
    data['email'] = email;
    data['phone'] = phone;
    data['subject'] = subject;
    data['message'] = message;
    contactUs(data, setResponse).then(() => {
      setName('');
      setEmail('');
      setPhone('');
      setSubject('');
      setMessage('');
      console.log(response);
      alert('thanks, we will contact with you soon');
    });
  }
  
  return (
    <div className="w-full h-full">
      <Header Section="CustomerService" />
      <div className="w-full h-full px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">customers service</p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">customers service</h1>
        </div>
        <div className="grid grid-cols-1  gap-y-5  lg:grid-cols-2 mt-10 gap-x-5">
          <div className="flex flex-col">
            <h1 className="font-Lato-Bold text-2xl mb-10">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at
              dictum risus
            </h1>
            <p className="font-Lato-Regular text-xl mb-10 ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at
              dictum risus, non suscipit arcu. Quisque aliquam posuere tortor,
              sit amet convallis nunc scelerisque in.
            </p>
            <p className="font-Lato-Regular text-xl ">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nemo quo
              laboriosam, dolorum ducimus aliquam consequuntur!
            </p>
          </div>
          <div className="flex justify-end items-end">
            <img src={ContactUsImg} className="" />
          </div>

          <div className="w-full">
            <div className="w-fit flex flex-col  ">
              <p className="font-Lato-Bold  text-4xl w-fit">Contact US</p>
              <hr className="border-2 border-[#B7C933] w-[20%] mt-2" />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-5 w-full">
              <input
                placeholder="Name"
                type="text"
                className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                onChange={e => setName(e.target.value)}
                value={name}
              />
              <input
                placeholder="Email"
                type="email"
                className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
              <input
                placeholder="Phone"
                type="tel"
                className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                onChange={e => setPhone(e.target.value)}
                value={phone}
              />
              <input
                placeholder="Subject"
                type="text"
                className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                onChange={e => setSubject(e.target.value)}
                value={subject}
              />
              <textarea
                rows={4}
                placeholder="Your message"
                className="col-start-1 col-end-3 border-[1px] border-[#DFDFDF] rounded-md p-2"
                onChange={e => setMessage(e.target.value)}
                value={message}
              />

              <div className="bg-gradient-to-r from-[#4A5428] to-[#B7C933] w-fit py-4 px-2 lg:px-10 rounded-xl  border-2 cursor-pointer"
                onClick={sendMessage}
                >
                <p className="text-xl  font-Lato-Bold text-white">
                  Send message
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full mt-5 lg:mt-0  justify-center items-center ">
            <div className="flex space-x-5 lg:w-1/2 w-full">
              <img src={LocationIcon} />
              <div className="flex flex-col  ">
                <h1 className="font-Lato-Bold text-xl">Address</h1>
                <p className="font-Lato-Regular">
                  CA 560 Bush St & 20th Ave, Apt 5 San Francisco, 230909, Canada
                </p>
              </div>
            </div>

            <div className="flex space-x-5 mt-5  lg:w-1/2 w-full">
              <img src={EmailIcon} />
              <div className="flex flex-col">
                <h1 className="font-Lato-Bold text-xl">Email</h1>
                <p className="font-Lato-Regular">Lap peo@email.com</p>
                <p className="font-Lato-Regular">Lap peo@email.com</p>
              </div>
            </div>

            <div className="flex space-x-5 mt-5  lg:w-1/2 w-full">
              <img src={PhoneIcon} />
              <div className="flex flex-col">
                <h1 className="font-Lato-Bold text-xl">Phone</h1>
                <p className="font-Lato-Regular">+44 587 154756</p>
                <p className="font-Lato-Regular">+55 5555 14574</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CustomerService;
