import axios from "axios";


export const storeGet = async (set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-from-store')
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}

export const storeAdd = async (data, setError) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/add-to-store', data)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
    console.log(err);
      // setError(err.response.data.errors);
  }); 
}

export const storeEdit = async (id, data, setError) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/update-store/' + id, data)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
    console.log(err);
      // setError(err.response.data.errors);
  }); 
}
export const storeDelete = async (id, setError) => {
  return await axios.delete('https://aurora-team.com/labsWebsite/public/api/delete-store/' + id)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
    console.log(err);
      // setError(err.response.data.errors);
  }); 
}
  

export const storeGetById = async (id) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/show-store/' + id)
}