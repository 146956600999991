import React from "react";

function Fees({ section }) {
  return (
    <div
      className={`${
        section === "FEES & CODES" ? "block" : "hidden"
      } w-full h-full pt-5`}
    >
      <div className="flex flex-col space-y-5">
        <div className="space-y-4">
          <h1 className="font-Lato-Bold text-lg">Test Setup Resources</h1>
          <p className="font-Lato-Regular">
            -Authorized users can sign in to{" "}
            <span className="font-Lato-Bold text-[#B7C933]">Test Prices</span>{" "}
            for detailed fee information.
          </p>
          <p className="font-Lato-Regular">
            -Clients without access to Test Prices can contact{" "}
            <span className="font-Lato-Bold text-[#B7C933]">
              Customer Service
            </span>
            24 hours a day, seven days a week.
          </p>
          <p className="font-Lato-Regular">
            -Prospective clients should contact their Regional Manager. For
            assistance, contact{" "}
            <span className="font-Lato-Bold text-[#B7C933]">
              Customer Service.
            </span>
          </p>
        </div>
        <div className="space-y-4">
          <h1 className="font-Lato-Bold text-lg">ANALYSES CLASSIFICATION </h1>
          <p className="font-Lato-Regular">
            This test has been cleared, approved, or is exempt by the US Food
            and Drug
          </p>
          <p>
            Administration and is used per manufacturer's instructions.
            Performance{" "}
          </p>
          <p className="font-Lato-Regular">
            characteristics were verified by Mayo Clinic in a manner consistent
            with CLIA requirements.
          </p>
        </div>
        <div className="w-full ">
          <h1 className="font-Lato-Bold text-lg mb-3">CPT CODE INFORMATION </h1>
          <p className="font-Lato-Regular mb-3">86794</p>
          <h1 className="font-Lato-Bold text-lg"> LOINC® INFORMATION </h1>

          <table className="w-full mt-5">
            <tr className="border-b-[1px] border-[#707070] ">
              <td className="px-2  pb-2 font-Lato-Regular">Analyses ID</td>
              <td className="px-2 pb-2 font-Lato-Regular">
                Analyse Order Name{" "}
              </td>
              <td className="px-2 pb-2 font-Lato-Regular">Order LOINC Value</td>
            </tr>
            <tr className=" bg-[#F5F5F5]">
              <td className="px-2 py-2 font-Lato-Regular">VZIKM</td>
              <td className="px-2 py-2 font-Lato-Regular">
                Zika Virus MAC-ELISA, IgM, S
              </td>
              <td className="px-2 py-2 font-Lato-Regular">80824-6</td>
            </tr>
          </table>
          <table className="w-full mt-10">
            <tr className="border-b-[1px] border-[#707070] ">
              <td className="px-2 pr-6 pb-2 font-Lato-Regular">Result ID</td>
              <td className="px-2 pb-2 font-Lato-Regular">
                Analyse Result Name
              </td>
              <td className="px-2 pb-2 font-Lato-Regular">
                Result LOINC Value
              </td>
            </tr>
            <tr className=" bg-[#F5F5F5]">
              <td className="px-2 py-2 font-Lato-Regular">VZIKM</td>
              <td className="px-2 py-2 font-Lato-Regular">
                Zika Virus MAC-ELISA, IgM, S
              </td>
              <td className="px-2 py-2 font-Lato-Regular">80824-6</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Fees;
