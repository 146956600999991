import React, { useState, useEffect } from "react";
import HeaderDB from "../../components/DashBoard/HeaderDB";
import SideBarDB from "../../components/DashBoard/SideBarDB";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";

import { Link } from "react-router-dom";

import { VscListFlat } from "react-icons/vsc";
import MessageDBPangration from "../../components/DashBoard/Message/MessageDBPangration";
import ShowMessage from "../../components/DashBoard/Message/ShowMessage";
import DeleteMessage from "../../components/DashBoard/Message/DeleteMessage";

import {getMessages} from '../../Api/Dashboard'

// const labs = [
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
//   {
//     id: 1,
//     name: "wewe",
//     email: "aadfa@faf.com",
//     subject: "adsfasfsaf",
//   },
// ];

function MessageDB() {
  const [OpenShowMessage, setOpenShowMessage] = useState(false);
  const [OpenDeleteMessage, setOpenDeleteMessage] = useState(false);

  const [labs, setLabs] = useState(false);
  const [selectedMessageId, setSelectedMessageId] = useState(false);
  useEffect(() => {
    getMessages(setLabs);
  }, []);

  function drawer() {
    document.getElementById("drawerBody").classList.remove("hidden");
    document
      .getElementById("drawerBody")
      .classList.add(
        "flex",
        "fixed",
        "h-screen",
        "w-full",
        "bg-transparent",
        "top-0",
        "left-0",
        "lg:hidden",
        "z-10"
      );
  }
  function closeDrawer() {
    document.getElementById("drawerBody").classList.add("hidden");
  }
  return (
    <div className="w-full h-full pr-5 p-5  bg-[#F2F4F7]">
      <ShowMessage open={OpenShowMessage} setOpen={setOpenShowMessage} selectedMessageId={selectedMessageId}/>
      <DeleteMessage open={OpenDeleteMessage} setOpen={setOpenDeleteMessage} selectedMessageId={selectedMessageId} />

      <div className="w-full flex ">
        <div className="bg-white mr-[-1rem] lg:mr-0 rounded-l-xl ">
          <VscListFlat
            id="drawerbtn"
            className="text-black  text-xl m-2  cursor-pointer w-10 h-20 lg:hidden "
            onClick={() => drawer()}
          />
        </div>
        <HeaderDB />
      </div>
      <div className="flex ">
        <SideBarDB page="Message" />
        <div className="w-full h-full lg:ml-8 mt-10">
          <div className="flex w-full justify-between">
            <div className="flex w-fit space-x-2">
              <div className="w-fit pr-2 bg-white rounded-lg flex items-center mr-5">
                <select className=" w-fit  rounded-lg font-Poppins-Medium  text-base outline-none px-4 py-2 cursor-pointer">
                  <option value="" selected disabled hidden>
                    Sort by
                  </option>
                  <option value="A-Z">A-Z</option>
                  <option value="Z-A">Z-A</option>
                </select>
              </div>
            </div>
          </div>

          {/* Pangration */}
          {
            labs && (
              <MessageDBPangration
                itemsPerPage={8}
                Data={labs}
                setOpenDeleteMessage={setOpenDeleteMessage}
                setOpenShowMessage={setOpenShowMessage}
                setSelectedMessageId={setSelectedMessageId}
              />
            )
          }

          {/* Drawer */}
          <div id="drawerBody" className=" hidden  ">
            <div
              id="drawer"
              className=" w-full bg-[#0D2135] opacity-80 h-full md:w-1/2"
            >
              <div className="p-4">
                <AiOutlineClose
                  className="text-xl text-white cursor-pointer "
                  onClick={() => closeDrawer()}
                />
              </div>
              <div className="flex flex-col ml-10 md:ml-20 space-y-1 w-full justify-center  h-4/5 text-white ">
                {/* border-b-2 border-[#847244] */}
                <Link
                  to="/"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center  text-xl  p-2 md:w-1/2 w-3/4"
                >
                  <div className="font-Poppins-Regular text-sm">Home</div>
                </Link>
                <Link
                  to="/Patients"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2 "
                >
                  <div className="font-Poppins-Regular text-sm">Patients </div>
                </Link>
                <Link
                  to="/Analytic"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Analytics</div>
                </Link>
                <Link
                  to="/Doctors"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Doctors</div>
                </Link>
                <Link
                  to="/Labs"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4   text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Labs </div>
                </Link>
                <Link
                  to="/Staff"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Staff</div>
                </Link>
                <Link
                  to="/Store"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Store</div>
                </Link>
                <Link
                  to="/Reports"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Reports</div>
                </Link>
                <Link
                  to="/"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Accounting</div>
                </Link>
                <Link
                  to="/Suppliers"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Suppliers</div>
                </Link>
                <Link
                  to="/Settings"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Setting</div>
                </Link>
                <Link
                  to="/"
                  className="hover:bg-black rounded-xl cursor-pointer flex justify-center md:w-1/2 w-3/4  text-xl p-2"
                >
                  <div className="font-Poppins-Regular text-sm">Logout</div>
                </Link>
              </div>
            </div>
            <div className="hidden sm:block h-full w-full bg-black opacity-40" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageDB;
