import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import CardSupplier from "../../components/Sample/CardSupplier";
import Cart from "../../components/Sample/Cart";
import CartIcon from "../../Images/CartIcon.svg";
import {getProducts} from '../../Api/cart';
function TransportSupplies() {
  const [OpenCart, setOpenCart] = useState(false);
  const [data, setData] = useState([]);
  useEffect(()=>{
    getProducts(setData);
  }, []);

  return (
    <div className="w-full h-full">
      <Cart open={OpenCart} setOpen={setOpenCart} />
      <Header Section="Sample" />
      <div className="w-full h-full flex flex-col px-20 mt-10  mb-20">
        <div className="w-full items-center flex justify-between border-b-2 pb-2">
          <div className="w-full flex flex-col lg:flex-row lg:space-x-5">
            <div className="flex ">
              <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
                Home
              </Link>
              <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
              <Link
                to="/Sample"
                className="font-Lato-Regular text-xl text-[#B7C933]"
              >
                Sample
              </Link>
            </div>
            <p className="font-Lato-Regular text-xl w-[90%] lg:w-fit lg:pl-5 mt-2">
              Sample collection and transport supplies
            </p>
          </div>
          <img
            src={CartIcon}
            className="cursor-pointer"
            onClick={() => setOpenCart(true)}
          />
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">
            Sample collection and transport supplies
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-10 gap-y-5">
          {data.map((item, key)=>(
            <CardSupplier key={key} Data={item} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TransportSupplies;
