import axios from "axios";

// messages
export const getMessages = async (set, setError) => {
    return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-message')
    .then(res => {
      set(res.data);
    })
    .catch(err => {
        // setError(err.response.data);
    }); 
}

export const getMessageById = async (id, set, setError) => {
  console.log(axios.defaults.headers);
    return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-message/'+ id)
    .then(res => {
      set(res.data);
    })
    .catch(err => {
        // setError(err.response.data);
    }); 
}

export const deleteMessage = async (id) => {
    return await axios.delete('https://aurora-team.com/labsWebsite/public/api/delete-message/'+ id)
    .then(res => {
      // set(res.data);
    })
    .catch(err => {
        // setError(err.response.data);
    }); 
}


// products

export const getProducts = async (set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/get-all-products')
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}


export const showProduct = async (id, set, setError) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/show-product/'+id)
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}

export const getProduct = async (id) => {
  return await axios.get('https://aurora-team.com/labsWebsite/public/api/show-product/'+id);
}
export const addProduct = async (data, set, setError) => {
  return await axios.post('https://aurora-team.com/labsWebsite/public/api/add-product', data)
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
      // console.log(err);
  }); 
}

export const editProduct = async (id, data, set, setError) => {
  return await axios.put('https://aurora-team.com/labsWebsite/public/api/update-product/' + id , data)
  .then(res => {
    set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
      // console.log(err);
  }); 
}

export const deleteProduct = async (id) => {
  return await axios.delete('https://aurora-team.com/labsWebsite/public/api/delete-product/'+ id)
  .then(res => {
    // set(res.data);
  })
  .catch(err => {
      // setError(err.response.data);
  }); 
}