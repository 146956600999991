import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import AboutSystemImage from "../Images/AboutSystemImage.svg";
import ServicesImgaeVideo from "../Images/ServicesImgaeVideo.svg";
import Visa from "../Images/Visa.svg";
import PayPal from "../Images/PayPal.svg";
import MasterCard from "../Images/MasterCard.svg";
import AmericanExpress from "../Images/AmericanExpress.svg";
import { Link } from "react-router-dom";
import { GoPrimitiveDot } from "react-icons/go";
import logo from "../Images/Logo.svg";

function Profile() {
  return (
    <div className="w-full h-full">
      <Header Section="Profile" />
      <div className="w-full h-full px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex  space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">Profile</p>
        </div>
      </div>

      <div className="flex flex-col  lg:pr-20 mt-10">
        <div className="w-full flex flex-col justify-center items-center">
          <div className="w-full flex flex-col justify-center  items-center lg:items-start lg:w-full lg:pl-20">
            <p className="font-Lato-Bold text-3xl">Profile</p>
          </div>
        </div>
        <div className="lg:pl-20 mt-10">
          <div className="w-full flex flex-col lg:flex-row justify-between bg-[#F5F5F5] rounded-xl pb-10">
            <div className="mt-5 mx-auto px-5 lg:px-0 lg:ml-14 w-fit flex flex-col">
              <div className="flex space-x-20">
                <img src={logo} className="w-[164px] h-[164px] " />
                <div className="flex flex-col space-y-4 m-auto">
                  <p className="font-Lato-Regular ">Narmeen S.</p>
                  <p className="font-Roboto-Regular text-[#C4C4C4]">
                    Laboratory name
                  </p>
                  <p className="font-Roboto-Regular text-[#C4C4C4]">
                    The name of the laboratory manager
                  </p>
                </div>
              </div>
              <div className="flex flex-col bg-white rounded-xl mt-10 p-2 lg:p-5 space-y-5 pb-10">
                <div className="flex space-x-10">
                  <h1 className="font-Roboto-Regular text-2xl">Phone:</h1>
                  <p className="font-Roboto-Regular text-xl text-[#C4C4C4]">
                    +970 592 736075
                  </p>
                </div>
                <div className="flex space-x-12">
                  <h1 className="font-Roboto-Regular text-2xl">Email:</h1>
                  <p className="font-Roboto-Regular text-xl text-[#C4C4C4]">
                    example@gmail.com
                  </p>
                </div>
                <div className="flex space-x-5">
                  <h1 className="font-Roboto-Regular text-2xl">Address:</h1>
                  <p className="font-Roboto-Regular text-xl text-[#C4C4C4]">
                    1861 Bayonne Ave, Manchester Township, NJ, 08759
                  </p>
                </div>
              </div>
            </div>
            <div className="flex justify-center h-fit space-x-5 mr-5">
              <Link
                to="/Profile/AccountSettings"
                className="bg-gradient-to-r from-[#4A5428] to-[#B7C933] h-14 flex items-center px-5 rounded-xl mt-14 border-2"
              >
                <p className="text-xl  font-Lato-Bold text-white">
                  Account Settings
                </p>
              </Link>
              <div className="bg-[#FFFFFF] border-[#93A750] w-fit flex items-center  px-8 rounded-xl mt-14 border-2">
                <p className="text-xl  font-Lato-Bold text-[#93A750]">
                  invoices
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Profile;
