import Footer from "../components/Footer";
import Header from "../components/Header";
import HomeCover from "../Images/HomeCover.svg";
import AboutSystemImage from "../Images/AboutSystemImage.svg";
import ServicesImgaeVideo from "../Images/ServicesImgaeVideo.svg";
import Visa from "../Images/Visa.svg";
import PayPal from "../Images/PayPal.svg";
import MasterCard from "../Images/MasterCard.svg";
import AmericanExpress from "../Images/AmericanExpress.svg";
import AddTestsIcon from "../Images/AddTestsIcon.svg";
import TestPriceIcon from "../Images/TestPriceIcon.svg";
import SampleCollectionIcon from "../Images/SampleCollectionIcon.svg";
import TransferBacteriaSampleIcon from "../Images/TransferBacteriaSampleIcon.svg";
import InstructionsIcon from "../Images/InstructionsIcon.svg";
import ExaminationIcon from "../Images/ExaminationIcon.svg";
import TransportationGuideIcon from "../Images/TransportationGuideIcon.svg";
import CDCIcon from "../Images/CDCIcon.svg";
import CourierInstructionsIcon from "../Images/CourierInstructionsIcon.svg";
import InfectiousSubstancesIcon from "../Images/InfectiousSubstancesIcon.svg";
import CollectingTransportingIcon from "../Images/CollectingTransportingIcon.svg";
import NewsCover from "../Images/NewsCover.svg";
import LocationIcon from "../Images/LocationIcon.svg";
import EmailIcon from "../Images/EmailIcon.svg";
import PhoneIcon from "../Images/PhoneIcon.svg";

import { GoPrimitiveDot } from "react-icons/go";
import LastNewsContainer from "../components/Home/LastNewsContainer";

import { useState } from "react";
import {contactUs} from '../Api/contactUs';
function Home() {
  const [response, setResponse] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const sendMessage = () => {
    const data = {};
    data['name'] = name;
    data['email'] = email;
    data['phone'] = phone;
    data['subject'] = subject;
    data['message'] = message;
    contactUs(data, setResponse).then(() => {
      setName('');
      setEmail('');
      setPhone('');
      setSubject('');
      setMessage('');
      console.log(response);
      alert('thanks, we will contact with you soon');
    });
  }
  
  return (
    <div className="w-full h-full">
      <Header Section="Home" />
      <div className="w-full h-full bg-no-repeat">
        <div className="relative h-fit mt-[-7rem] z-[-1]">
          <img src={HomeCover} />
          <div className="absolute top-72 pl-24 ">
            <p className="font-Lato-Bold text-[39px] w-3/4">
              Get Better
              <span className="text-[#B7C933]"> Laboratory and test</span>{" "}
              Services
            </p>
            <div className="border-[#B7C933] w-fit py-4 px-10 rounded-xl mt-24 border-2 cursor-pointer">
              <p className="text-xl font-Lato-Bold text-[#B7C933]">
                More About
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between lg:pr-24 mt-10">
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex flex-col justify-center items-center lg:items-start  lg:w-full lg:pl-20">
              <p className="font-Lato-Bold text-[35px]">About the system</p>
              <hr className="border-2 border-[#B7C933] w-1/4 mt-2" />
            </div>
            <div className="flex flex-col  items-center mb-20 lg:mb-0">
              <p className="font-Lato-Bold text-[27px] w-[75%] leading-relaxed mt-5">
                We Are The Trusted Experts We Keep Things Simple
              </p>
              <p className="text-[#707070] mt-5 w-[75%] text-[23px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at
                dictum risus, non suscipit arcu. Quisque aliquam posuere tortor,
                sit amet convallis nunc scelerisque in.
              </p>
              <p className="text-[#707070] mt-10 w-[75%] text-[23px]">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at
                dictum risus, non suscipit arcu. Quisque aliquam posuere tortor,
                sit amet convallis nunc scelerisque in.
              </p>
              <div className="bg-gradient-to-r from-[#4A5428] to-[#B7C933] w-fit py-4 px-10 rounded-xl mt-14 border-2">
                <p className="text-xl  font-Lato-Bold text-white">More About</p>
              </div>
            </div>
          </div>
          <img src={AboutSystemImage} />
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-between lg:pr-24 mt-10">
          <img src={ServicesImgaeVideo} />
          <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex flex-col justify-center items-center lg:items-start lg:w-full">
              <p className="font-Lato-Bold text-[35px]">Services</p>
              <hr className="border-2 border-[#B7C933] w-1/3 mt-2" />
            </div>
            <div className="flex flex-col items-start mb-10 lg:mb-0">
              <p className="text-[27px] font-Lato-Bold ml-2 lg:ml-0 w-[90%] mt-5">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </p>
              <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
                <GoPrimitiveDot className="text-2xl text-[#93A750]" />
                <p className="font-Lato-Regular text-[#707070] text-[23px]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div>
              <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
                <GoPrimitiveDot className="text-2xl text-[#93A750]" />
                <p className="font-Lato-Regular text-[#707070] text-[23px]">
                  Lorem ipsum dolor sit amet
                </p>
              </div>
              <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
                <GoPrimitiveDot className="text-2xl text-[#93A750]" />
                <p className="font-Lato-Regular text-[#707070] text-[23px]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                </p>
              </div>
              <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8">
                <GoPrimitiveDot className="text-2xl text-[#93A750]" />
                <p className="font-Lato-Regular text-[#707070] text-[23px]">
                  Lorem ipsum dolor sit amet, consectetur adipiscing
                </p>
              </div>
              <div className="flex items-center ml-2 lg:ml-[-0.3rem] mt-8 lg:mb-10">
                <GoPrimitiveDot className="text-2xl text-[#93A750]" />
                <p className="font-Lato-Regular text-[#707070] text-[23px]">
                  Lorem ipsum dolor sit amet
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full ">
          <div className="w-fit pl-16">
            <p className="font-Lato-Bold text-3xl">Payment methods</p>
            <hr className="border-2 border-[#B7C933] w-1/5 mt-2" />
          </div>
          <div className="w-full grid grid-cols-1 items-center justify-center md:grid-cols-2 lg:grid-cols-4 px-16 mt-8  bg-[#93A750]">
            <img src={Visa} className="mt-5 lg:mt-0 mx-auto" />
            <img src={PayPal} className="mt-5 lg:mt-0 mx-auto" />
            <img src={MasterCard} className="mt-5 lg:mt-0 mx-auto" />
            <img src={AmericanExpress} className="mx-auto" />
          </div>
        </div>

        <div className="w-full mt-20 px-16">
          <div className="w-fit ">
            <p className="font-Lato-Bold text-3xl">Ordering & Results</p>
            <hr className="border-2 border-[#B7C933] w-1/6 mt-2" />
          </div>
          <div className="grid grid-cols-1  gap-y-5 lg:gap-y-0 lg:grid-cols-2 mt-10 pl-5 gap-x-5">
            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={AddTestsIcon} />
              <div className="flex flex-col">
                <p className="text-xl font-Lato-Bold mb-1">
                  Add Tests to an Order
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={TestPriceIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1">Test Prices</p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mt-16 px-16">
          <div className="w-fit ">
            <p className="font-Lato-Bold text-3xl">
              Sample collection and transportation
            </p>
            <hr className="border-2 border-[#B7C933] w-[10%] mt-2" />
          </div>

          <div className="grid  grid-cols-1 lg:grid-cols-2 mt-10 pl-5 gap-y-14 gap-x-5">
            <div className="flex space-x-4 border-[1px] w-full p-5 items-center rounded-xl">
              <img src={SampleCollectionIcon} className="w-20" />
              <div className="flex flex-col">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  Sample collection and transport supplies
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={TransferBacteriaSampleIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  Collection and transfer of bacterial samples
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={CollectingTransportingIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  Collecting and transporting urine samples
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={InstructionsIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  Instructions according to sample type
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={ExaminationIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  Examinations that require protection from light
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={TransportationGuideIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  Sample collection and transportation guide
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={CDCIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  CDC statement
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
              <img src={CourierInstructionsIcon} className="w-20" />
              <div className="flex flex-col ">
                <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                  Courier instructions
                </p>
                <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                  neque quam
                </p>
              </div>
            </div>

            <div className="flex space-x-4  lg:col-start-1 lg:col-end-3 w-full justify-center ">
              <div className="flex space-x-4 border-[1px] p-5 rounded-xl lg:w-1/2">
                <img src={InfectiousSubstancesIcon} className="w-20" />
                <div className="flex flex-col ">
                  <p className="text-xl font-Lato-Bold mb-1 w-1/2">
                    Infectious substances
                  </p>
                  <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nulla neque quam
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ backgroundImage: `url(${NewsCover})` }}
          className="bg-cover w-full h-full flex flex-col justify-center items-center pb-52"
        >
          <div className="w-fit flex flex-col  mt-48">
            <p className="font-Lato-Bold text-white text-4xl w-fit">
              latest news
            </p>
            <hr className="border-2 border-[#B7C933] w-[20%] mt-2" />
          </div>
          <div className="w-full px-24 space-y-5 mt-5">
            <LastNewsContainer />
            <LastNewsContainer />
            <LastNewsContainer />

            <div className="border-white w-fit py-4 px-10 rounded-xl mt-20 mx-auto border-2 cursor-pointer">
              <p className="text-xl font-Lato-Bold text-white">More About</p>
            </div>
          </div>
        </div>

        <div className="flex lg:px-28 px-10">
          <div className="w-full">
            <div className="w-fit flex flex-col  mt-32">
              <p className="font-Lato-Bold  text-4xl w-fit">Contact US</p>
              <hr className="border-2 border-[#B7C933] w-[20%] mt-2" />
            </div>
            <div className="flex flex-col lg:flex-row justify-between space-y-8 items-center lg:space-y-0 lg:space-x-5">
              <div className="grid grid-cols-2 gap-4 mt-5 w-full">
                <input
                  placeholder="Name"
                  type="text"
                  className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                  onChange={e => setName(e.target.value)}
                  value={name}
                />
                <input
                  placeholder="Email"
                  type="email"
                  className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                />
                <input
                  placeholder="Phone"
                  type="tel"
                  className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                  onChange={e => setPhone(e.target.value)}
                  value={phone}
                />
                <input
                  placeholder="Subject"
                  type="text"
                  className="border-[1px] border-[#DFDFDF] rounded-md p-2"
                  onChange={e => setSubject(e.target.value)}
                  value={subject}
                />
                <textarea
                  rows={4}
                  placeholder="Your message"
                  className="col-start-1 col-end-3 border-[1px] border-[#DFDFDF] rounded-md p-2"
                  onChange={e => setMessage(e.target.value)}
                  value={message}
                />

                <div className="bg-gradient-to-r from-[#4A5428] to-[#B7C933] w-fit py-4 px-10 rounded-xl  border-2 cursor-pointer"
                onClick={sendMessage}
                >
                  <p className="text-xl  font-Lato-Bold text-white">
                    Send message
                  </p>
                </div>
              </div>
              <div className="flex flex-col w-1/2">
                <div className="flex space-x-5">
                  <img src={LocationIcon} />
                  <div className="flex flex-col">
                    <h1 className="font-Lato-Bold text-xl">Address</h1>
                    <p className="font-Lato-Regular">
                      CA 560 Bush St & 20th Ave, Apt 5 San Francisco, 230909,
                      Canada
                    </p>
                  </div>
                </div>

                <div className="flex space-x-5 mt-5">
                  <img src={EmailIcon} />
                  <div className="flex flex-col">
                    <h1 className="font-Lato-Bold text-xl">Email</h1>
                    <p className="font-Lato-Regular">Lap peo@email.com</p>
                    <p className="font-Lato-Regular">Lap peo@email.com</p>
                  </div>
                </div>

                <div className="flex space-x-5 mt-5">
                  <img src={PhoneIcon} />
                  <div className="flex flex-col">
                    <h1 className="font-Lato-Bold text-xl">Phone</h1>
                    <p className="font-Lato-Regular">+44 587 154756</p>
                    <p className="font-Lato-Regular">+55 5555 14574</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
