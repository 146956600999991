import React from "react";

function Performance({ section }) {
  return (
    <div
      className={`${
        section === "PERFORMANCE" ? "block" : "hidden"
      } w-full h-full pt-5`}
    >
      <div className="flex flex-col space-y-5">
        <div className="space-y-2">
          <h1 className="font-Lato-Bold text-lg">METHOD DESCRIPTION </h1>
          <p className="font-Lato-Regular text-lg mt-2">
            The ZIKV Detect 2.0 IgM Capture enzyme-linked immunosorbent assay
            (ELISA) is for the detection of human IgM antibodies targeting the
            Zika virus (ZIKV) envelope glycoproteins. Polystyrene microtiter
            wells are precoated with polyclonal capture antibodies against human
            IgM. Positive control, negative control, and patient serum samples
            are diluted into a sample dilution buffer and then added to the
            ELISA plate in appropriate locations. After incubation and washing,
            a subsequent ready-to-use (RTU) ZIKV antigen (Zika Ag), a
            cross-reactive control antigen, and a normal cell antigen are added
            separately to each corresponding well. After incubation and washing,
            a RTU secondary antibody solution is added to each well. After a
            subsequent incubation and wash steps, an enzyme conjugate solution
            comprising horseradish peroxidase-labeled antimouse antibody is
            added to each well. After washing, wells are incubated with a
            tetramethylbenzidine substrate. An acidic stop solution is then
            added, and the degree of enzymatic turnover is determined by the
            absorbance (optical density) measurement at 450 nanometers. If human
            IgM antibodies targeting the ZIKV envelope glycoproteins are
            present, a complex is formed consisting of the IgM, antigen,
            secondary antibody, and conjugate. If IgM antibodies targeting the
            ZIKV envelope glycoproteins are not present, then the antigen,
            antibody, and conjugate are washed away.(Package insert: InBios Zika
            Detect 2.0 IgM Capture ELISA. InBios International, Inc; 05/23/2019)
          </p>
          <h1 className="font-Lato-Bold text-lg mt-2">PDF REPORT</h1>
          <p className="font-Lato-Regular text-lg mt-2">No</p>
          <h1 className="font-Lato-Bold text-lg mt-2">DAY(S) PERFORMED</h1>
          <p className="font-Lato-Regular text-lg mt-2">
            Bimonthly on the first and third Wednesday
          </p>
          <h1 className="font-Lato-Bold text-lg mt-2">REPORT AVAILABLE</h1>
          <p className="font-Lato-Regular text-lg mt-2">
            Same day/1 to 14 days
          </p>
          <h1 className="font-Lato-Bold text-lg mt-2">
            SPECIMEN RETENTION TIME{" "}
          </h1>
          <p className="font-Lato-Regular text-lg mt-2">90 days</p>
          <h1 className="font-Lato-Bold text-lg mt-2">
            PERFORMING LABORATORY LOCATION{" "}
          </h1>
          <p className="font-Lato-Regular text-lg mt-2">Rochester</p>
        </div>
      </div>
    </div>
  );
}

export default Performance;
