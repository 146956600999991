import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import NewTest from "../Images/TestCatalog/NewTest.svg";
import AllTest from "../Images/TestCatalog/AllTest.svg";
import ResultsForm from "../Images/TestCatalog/ResultsForm.svg";
import SearchBar from "../Images/TestCatalog/SearchBar.svg";
import TestAlgorithm from "../Images/TestCatalog/TestAlgorithm.svg";
import TestRequestPolicy from "../Images/TestCatalog/TestRequestPolicy.svg";
import TestRequest from "../Images/TestCatalog/TestRequest.svg";
import { Link } from "react-router-dom";

function TestCatalog() {
  return (
    <div className="w-full h-full">
      <Header Section="TestCatalog" />
      <div className="w-full h-full px-5 lg:px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <p className="font-Lato-Regular text-xl">Test Catalog</p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">Test catalog</h1>
        </div>
        <div className="grid grid-cols-1  gap-y-5 lg:grid-cols-2 mt-10 pl-5 gap-x-5">
          <Link
            to="/TestCatalog/NewTest"
            className="flex space-x-4 border-[1px] p-5 rounded-xl"
          >
            <img src={NewTest} className="w-20" />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">New test</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                Stay up to date on new analyzes published in the Analytics
                Catalog
              </p>
            </div>
          </Link>
          <Link
            to="/TestCatalog/AllTest"
            className="flex space-x-4 border-[1px] p-5 rounded-xl"
          >
            <img src={AllTest} className="w-20" />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">All test</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                Stay up to date on new analyzes published in the Analytics
                Catalog
              </p>
            </div>
          </Link>
          <Link
            to="/TestCatalog/TestRequest"
            className="flex space-x-4 border-[1px] p-5 rounded-xl"
          >
            <img src={TestRequest} className="w-20" />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">test request</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                Learn how to request an analysis if you already have an account
              </p>
            </div>
          </Link>
          <Link
            to="/TestCatalog/OverView"
            className="flex space-x-4 border-[1px] p-5 rounded-xl"
          >
            <img src={TestRequestPolicy} className="w-20" />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">test request policy</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                Review full lab testing policies, including billing and sample
                details and results
              </p>
            </div>
          </Link>
          <div className="flex space-x-4 border-[1px] p-5 rounded-xl">
            <img src={TestAlgorithm} className="w-20" />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">test algorithm</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                Step-by-step procedures for doctors and laboratories to request
                appropriate analyzes.
              </p>
            </div>
          </div>
          <Link
            to="/TestCatalog/ResultsForm"
            className="flex space-x-4 border-[1px] p-5 rounded-xl"
          >
            <img src={ResultsForm} className="w-20" />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">Results form</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                See the form for each analysis
              </p>
            </div>
          </Link>
          <Link
            to="/TestCatalog/SearchBar"
            className="flex lg:mx-auto lg:w-fit space-x-4 lg:col-start-1 lg:col-end-3 border-[1px] p-5  rounded-xl"
          >
            <img src={SearchBar} className="w-20" />
            <div className="flex flex-col">
              <p className="text-xl font-Lato-Bold mb-1">Search bar</p>
              <p className="font-Lato-Regular  text-[#707070] w-4/5 text-xl">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
                neque quam
              </p>
            </div>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TestCatalog;
