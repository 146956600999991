import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import ListInvoices from "../../components/Profile/ListInvoices";

function Invoices() {
  const tests = [
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
    {
      TestID: "VZIKM",
      TestName: "Zika Virus, IgM Antibody Capture ELISA, Serum",
      Published: "11/22/2022",
    },
  ];
  return (
    <div className="w-full h-full">
      <Header Section="TestCatalog" />
      <div className="w-full h-full flex flex-col px-20 mt-10  mb-20">
        <div className="w-full flex space-x-5 border-b-2 pb-2">
          <Link to="/" className="font-Lato-Regular text-xl text-[#B7C933]">
            Home
          </Link>
          <p className="font-Lato-Regular text-xl text-[#B7C933]">|</p>
          <Link
            to="/Profile"
            className="font-Lato-Regular text-xl text-[#B7C933]"
          >
            profile
          </Link>
          <p className="font-Lato-Regular text-xl pl-5">Invoices</p>
        </div>
        <div className="w-full mt-10">
          <h1 className="text-2xl font-Lato-Bold">Invoices</h1>
          <p className="font-Lato-Regular text-lg mt-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <ListInvoices Data={tests} />
      </div>
      <Footer />
    </div>
  );
}

export default Invoices;
