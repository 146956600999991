import React from "react";

function Clinical({ section }) {
  return (
    <div
      className={`${
        section === "CLINICAL & INTERPRETIVE" ? "block" : "hidden"
      } w-full h-full pt-5`}
    >
      <div className="flex flex-col space-y-5">
        <div className="space-y-5">
          <h1 className="font-Lato-Bold text-lg">USEFUL FOR</h1>
          <p className="font-Lato-Regular">
            Screening for the presence of IgM-class antibodies to Zika virus
          </p>
          <p className="font-Lato-Regular">
            This test is
            <span className="font-Lato-Bold"> not intended for </span>
            medical-legal use.
          </p>
          <p className="font-Lato-Regular">
            This test is
            <span className="font-Lato-Bold"> not recommended for</span>{" "}
            asymptomatic couples attempting conception.
          </p>
        </div>
        <div className="space-y-3">
          <h1 className="font-Lato-Bold text-lg">TESTING ALGORITHM </h1>

          <p className="font-Lato-Regular">
            The following algorithms are available:
          </p>
          <p>-Assessment for Zika Virus Infection</p>
          <p className="font-Lato-Regular">
            -Mosquito-borne Disease Laboratory Testing
          </p>
        </div>
        <div className="w-full space-y-5">
          <h1 className="font-Lato-Bold text-lg">CLINICAL INFORMATION </h1>
          <p className="font-Lato-Regular">
            Zika virus is an RNA virus in the genus Flavivirus and is primarily
            transmitted through the bite of an infected Aedes species mosquito.
            Other means of transmission include through transfusion of blood and
            blood products, sexually through genital secretions, perinatally,
            vertically from mother to fetus, and potentially through contact
            with other body secretions such as tears and sweat.
          </p>
          <p className="font-Lato-Regular">
            Historically, most cases of Zika virus infection have occurred in
            parts of Africa and South-East Asia. However, Zika virus emerged in
            South America in early 2015 and is now endemic in over 50 countries
            in South, Central, and North America, including in several US
            territories and focal regions of the southern United States.
          </p>
          <p className="font-Lato-Regular">
            The majority (approximately 80%) of individuals infected with Zika
            virus are asymptomatic. Among symptomatic patients, fever, headache,
            retro-orbital pain, conjunctivitis, maculopapular rash, myalgias,
            and arthralgias are commonly reported. Notably, these symptoms are
            not distinct and can be seen with other emerging arboviruses,
            including dengue and chikungunya. Therefore, diagnostic testing for
            each of these viruses is recommended in patients returning for areas
            where these viruses cocirculate. Intrauterine or prenatal infection
            with Zika virus has been causally linked to development of
            microcephaly, with the greatest risk for fetal abnormality occurring
            if the infection is acquired during the first trimester. Finally,
            Zika virus has also been associated with development of
            Guillain-Barre syndrome.
          </p>
          <p className="font-Lato-Regular">
            A number of Zika virus serologic and nucleic acid amplification
            tests have received emergency use authorization through the US Food
            and Drug Administration. The recommended tests vary by the patient's
            symptoms, course of illness, and whether the patient is pregnant.
          </p>
          <p className="font-Lato-Regular">
            For the most up-to-date information regarding Centers for Disease
            Control and Prevention testing guidelines, visit www.cdc.gov/zika/.
          </p>
          <p className="font-Lato-Regular">
            These guidelines are reflected in Assessment for Zika Virus
            Infection.
          </p>
          <p className="font-Lato-Regular">
            Zika virus testing is not recommended for asymptomatic couples
            attempting conception, given the potential for false-positive and
            false-negative results. Additionally, it is well established the
            Zika virus may remain in reproductive fluids, despite negative
            serologic and molecular test results in blood and urine.
          </p>
        </div>

        <div className="w-full">
          <h1 className="font-Lato-Bold text-lg">REFERENCE VALUES </h1>
          <p className="font-Lato-Regular text-lg mt-2">Negative</p>

          <h1 className="font-Lato-Bold text-lg mt-2">INTERPRETATION </h1>
          <p className="font-Lato-Regular text-lg mt-2">
            See Assessment for Zika Virus Infection for a review of the
            recommended testing and interpretation of results. For the most
            recent Centers for Disease Control and Prevention guidelines for
            Zika virus testing visit www.cdc.gov/zika/.
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Presumptive Zika Positive: IgM-class antibodies to Zika virus (ZIKV)
            detected. This is a preliminary result and does not confirm evidence
            of ZIKV infection. Confirmatory testing may be required as
            determined by your local health department. False-positive results
            may occur in patients with other current or prior flavivirus
            infections (eg, dengue virus). For patients with less than 7 days of
            symptoms or last possible exposure to ZIKV, reverse
            transcription-polymerase chain reaction (RT-PCR) for ZIKV on serum
            and urine is recommended. A positive ZIKV RT-PCR result on either
            specimen is confirmatory for ZIKV infection.
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Other Flavivirus Positive: Antibodies to a flavivirus, not ZIKV,
            were detected. Consider targeted testing for IgM-class antibodies to
            dengue and/or West Nile viruses as appropriate, taking into
            consideration patient exposure and presentation.
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            Negative: No evidence of IgM-class antibodies to ZIKV. For specimens
            collected less than 7 days post symptom onset or possible ZIKV
            exposure, RT-PCR for ZIKV on serum and urine to exclude a
            false-negative ZIKV IgM result is recommended. For symptomatic
            patients with travel to dengue endemic areas, testing for IgM
            antibodies to dengue virus is also recommended.
          </p>
        </div>
        <div className="space-y-2 ">
          <h1 className="font-Lato-Bold text-lg">SPECIAL INSTRUCTIONS </h1>
          <p className="font-Lato-Regular text-lg mt-2">
            -Assessment for Zika Virus Infection
          </p>
          <p className="font-Lato-Regular text-lg mt-2">
            -Mosquito-borne Disease Laboratory Testing
          </p>
        </div>
      </div>
    </div>
  );
}

export default Clinical;
