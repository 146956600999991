/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import logo from "../../../Images/Logo.svg";
import {sendTestRequest} from '../../../Api/tests';

export default function SendRequestContainer({ open, setOpen, testId }) {
  const cancelButtonRef = useRef(null);
  const [Method, SetMethod] = useState("Register");

  const [error, setError] = useState();
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');

  const sendRequest = () => {
    const data = {};
    data['test_id'] = testId;
    data['name'] = name;
    data['phone'] = phone;
    data['address'] = address;
    data['email'] = email;
    sendTestRequest(data, setError).then(() => {
      setName('');
      setEmail('');
      setPhone('');
      setAddress('');
      console.log(error);
      close();
    });
  }
  

  function close() {
    setOpen(false);
  }
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          initialFocus={cancelButtonRef}
          onClose={() => close()}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto ">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0 ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative px-10 pb-8 bg-white rounded-2xl text-left overflow-hidden shadow-xl transform transition-all sm:my-2 sm:max-w-xl sm:w-full ">
                  <div className="bg-white ">
                    <div className="w-full flex  h-full items-center mt-5">
                      <div className="flex flex-col items-center justify-center w-full space-y-2">
                        <img src={logo} className="w-fit h-fit" />

                        <p className="text-[#4A4A4A]  justify-center font-Roboto-Bold flex flex-grow text-2xl ">
                          Scenario analysis request
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-col space-y-5 w-full m-auto mt-10">
                      <div className={`gap-5 grid grid-cols-2 `}>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Name"
                            placeholder="Name"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setName(e.target.value)}
                            value={name}
                          />
                        </div>
                        <div className="w-full break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Phone"
                            placeholder="Phone"
                            type="tel"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setPhone(e.target.value)}
                            value={phone}
                          />
                        </div>
                        <div className="w-full col-start-1 col-end-3 break-words border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="TheAddress"
                            placeholder="The address"
                            type="text"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setAddress(e.target.value)}
                            value={address}
                          />
                        </div>
                        <div className="w-full break-words col-start-1 col-end-3 border-[#E4E7EC] h-fit bg-white flex  items-center py-4 px-4    relative  border-[1px] rounded-xl ">
                          <input
                            name="Email"
                            placeholder="Email"
                            type="email"
                            className="w-full bg-white font-Cairo-Regular  text-[#707070] outline-0 ring-0"
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                          />
                        </div>

                        <div className="bg-white  flex flex-col col-start-1 col-end-3 mt-5">
                          <button
                            type="button"
                            className="flex flex-grow py-4 text-xl bg-gradient-to-r from-[#4A5428] to-[#B7C933] font-Roboto-Regular justify-center rounded-xl text-white"
                            onClick={sendRequest}
                          >
                            Send Request
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
